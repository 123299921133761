import { vOptT, vReqT } from "src/helpers/utils"
import { defineComponent, Transition } from "vue"

export const btn2_defaultEnabledClasses = "bg-green-800 hover:bg-green-700 active:bg-green-500 cursor-pointer"
export const btn2_disabledButLooksEnabledClasses = "bg-green-800"
export const btn2_redEnabledClasses = "bg-red-800 hover:bg-red-700 active:bg-red-600 cursor-pointer"

/**
 * Thin convenience wrapper around \<button\>. Defaults to "type=button".
 * Intended as a replacement for `<t-btn/>`, prefer this over t-btn.
 */
export const Btn2 = defineComponent({
  props: {
    commonClasses: vOptT("text-white font-medium rounded-md text-sm"),
    enabledClasses: vOptT(btn2_defaultEnabledClasses),
    disabledClasses: vOptT("bg-gray-300"),
    transitionClasses: vOptT("transition ease-out duration-150"),
    disabled: vOptT(false),
    type: vOptT<"submit" | "button">("button"),
  },
  emits: {
    click: () => true,
  },
  setup(props, {emit, slots}) {
    return () => {
      return (
        <button
          type={props.type}
          class={[props.commonClasses, props.transitionClasses, props.disabled ? props.disabledClasses : props.enabledClasses]}
          disabled={props.disabled}
          onClick={() => emit("click")}
        >
          {slots.default?.()}
        </button>
      )
    }
  }
})

// sorta nice but super tough to make it have a smooth animation no matter where you place it in DOM,
// you have to specify exact widths and heights otherwise surrounding content reflows cause ugly jank when things
// move by a few pixels. It has seemed necessary to mark the elements as css "contain: strict" to further reduce jank.
// maybe it would be a good idea to do that here so we dn't forget it, but it's not clear if we really always want that
// option.
export const BusyBtn2 = defineComponent({
  props: {
    busy: vReqT<boolean>(),
    disabled: vOptT<boolean>(),
  },
  emits: {
    click: () => true,
  },
  setup(props, ctx) {
    return () => {
      return <Btn2
        disabled={props.disabled}
        onClick={() => ctx.emit("click")}
      >
        <div class="relative">
          <Transition
            enter-active-class = "transition duration-400 ease-out"
            enter-from-class = {props.busy ? "-translate-y-1 opacity-0" : "translate-y-1 opacity-0"}
            enter-to-class = "translate-y-0 opacity-100"
            leaveActiveClass='opacity-50 duration-400'
            leaveToClass='opacity-0'
          >
            {props.busy
              ? <div key="xa" class="absolute top-0">{ctx.slots.busy?.()}</div>
              : <div key="xb" class="absolute top-0">{ctx.slots.notBusy?.()}</div>}
          </Transition>
          {/*take up space ... for ... what reason? ... if we don't do this the relative div has no height, and that causes problems but I don't really understand why */}
          <div class="invisible">&nbsp;</div>
        </div>
      </Btn2>
    }
  }
})

export interface BusyBtn2Slots {
  busy?: () => JSX.Element | null,
  notBusy?: () => JSX.Element | null,
}
