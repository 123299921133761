<template lang="pug">
FormKit.space-y-8.divide-y.divide-gray-200.border-gray-200(@submit='updateCompetitionDetails', type='form', :actions='false', v-if='ready' data-test="CompetitionOptions")
  TBtn(label='Update Competition Details', type='submit' data-test="submit1")
  .space-y-8.divide-y.divide-gray-200(class='sm:space-y-5')
    .mx-auto
      .grid.grid-cols-1.gap-12(class='lg:grid-cols-2')
        div(class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5')
          label.block.text-sm.font-medium.text-gray-700(
            for='form.competition',
            class='sm:mt-px sm:pt-2'
          )
            | Competition Name
          .mt-1.max-w-xs(class='sm:mt-0')
            FormKit(
              type='text',
              placeholder='Competition Name',
              name='form.competition',
              v-model='form.competition'
            )
        div(class='sm:grid sm:grid-cols-2 sm:gap-6 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5')
          label.block.text-sm.font-medium.text-gray-700(
            for='form.seasonUID',
            class='sm:mt-px sm:pt-2'
          )
            | Active Season
            .block.font-normal.mt-2.text-gray-500.mr-2 The season for which team rosters and coach assignments in this season are currently active. Not related to registration.
          .mt-1.max-w-xs(class='sm:mt-0')
            Seasons(v-model='form.seasonUID')
        div(class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5')
          label.block.text-sm.font-medium.text-gray-700(
            for='form.enabled',
            class='sm:mt-px sm:pt-2'
          )
            | Enabled
            .block.font-normal.mt-2.text-gray-500 inLeague system setting that indicates whether the competition is active
          .mt-1.max-w-xs.pl-4(class='sm:mt-0')
            FormKit(
              type='toggle',
              placeholder='Enabled',
              name='form.enabled',
              v-model='form.enabled',
              :on-value="1",
              :off-value="0"
            )
        div(class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5')
          label.block.text-sm.font-medium.text-gray-700(
            for='form.stackPlayLevelKey',
            class='sm:mt-px sm:pt-2'
          )
            | AYSO Program Type
          .mt-1.max-w-xs.pl-4(class='sm:mt-0')
            FormKit(
              type='select',
              name='stackPlayLevelKey',
              v-model='form.stackPlayLevelKey',
              :options='stackPlayLevelOptions'
            )
      .mt-4(class='sm:border-t sm:border-gray-200')
        h3.text-xl.leading-6.font-medium.text-gray-900.pt-4.pb-2.pl-2
          | Program Registration
        div(v-if="form.hasActiveRegistration" class='sm:border-t sm:border-gray-200 sm:pt-5 lg:col-span-2')
          quill-editor.bg-white(
            v-model:value='form.registrationDesc',
            :editor-toolbar='customToolbar',
            data-cy='overrideRichEditor',
            :options="{placeholder: 'Program description to be displayed during registration'}"
          )
      .grid.grid-cols-1.gap-12(class='lg:grid-cols-2')
        div(class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5')
          label.block.text-md.font-medium.text-gray-700(
            for='form.hasActiveRegistration',
            class='sm:mt-px sm:pt-2'
          )
            | Has Registration
            .block.font-normal.mt-2.text-gray-500 Include this competition when checking for programs with open player registration. If disabled, all registration-related dates and eligibility lists are ignored.
          .mt-1.max-w-xs.pl-4(class='sm:mt-0')
            FormKit(
              type='toggle',
              placeholder='Has Registration',
              name='form.hasActiveRegistration',
              v-model='form.hasActiveRegistration',
              :on-value="1",
              :off-value="0"
            )
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-md.font-medium.text-gray-700(
            for='form.defaultEligibility',
            class='sm:mt-px sm:pt-2'
          )
            | Default Eligibility
            .block.font-normal.mt-2.text-gray-500 If enabled, all players are eligible for this competition. Otherwise only players expressly eligible (whether by name/DOB, explicit invitation, or eligibility rule) may register.
          .mt-1.max-w-xs.pl-4(class='sm:mt-0')
            FormKit(
              type='toggle',
              placeholder='Default Eligibility',
              name='form.defaultEligibility',
              v-model='form.defaultEligibility',
              :on-value="1",
              :off-value="0"
            )
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-md.font-medium.text-gray-700(
            for='form.hideIfIneligible',
            class='sm:mt-px sm:pt-2'
          )
            | Hide if Ineligible
            .block.font-normal.mt-2.text-gray-500 If enabled, players not eligible for this program will not see the program displayed on the player registration menu. If disabled, it will be displayed but not selectable.
          .mt-1.max-w-xs.pl-4(class='sm:mt-0')
            FormKit(
              type='toggle',
              placeholder='Hide if Ineligible',
              name='form.hideIfIneligible',
              v-model='form.hideIfIneligible',
              :on-value="1",
              :off-value="0"
            )
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-md.font-medium.text-gray-700(
            for='form.ageCalcMethod',
            class='sm:mt-px sm:pt-2'
          )
            | Division / Age Calculation
            .block.font-normal.mt-2.text-gray-500 Birth Year, School Year (7/31), or Default to Season value
          .mt-1.max-w-xs(class='sm:mt-0')
            FormKit(
              type='select',
              name='form.ageCalcMethod',
              v-model='form.ageCalcMethod',
              :options='{ 0: "Default to Season value", 1: "Birth Year", 2: "School Year (7/31 Cutoff)" }'
            )
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-md.font-medium.text-gray-700(
            for='form.refSchedulerEmail',
            class='sm:mt-px sm:pt-2'
          )
            | BCC Registration Confirmations
            .block.font-normal.mt-2.text-gray-500 If specified, this comma-delimited list of email addresses will receive a copy of all registration confirmations for this program.
          .mt-1.max-w-xs.pl-4(class='sm:mt-0')
            FormKit(
              type='text',
              placeholder='Optional Email BCC',
              name='form.confirmationEmailCC',
              v-model='form.confirmationEmailCC'
            )
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-md.font-medium.text-gray-700(
            for='hasRatings',
            class='sm:mt-px sm:pt-2'
          )
            | Enable Ratings
            .block.font-normal.mt-2.text-gray-500 Players may be rated once per season, regardless of the number of programs in which they participate. If this option is enabled, coaches for this program will be able to enter ratings (and prompted to enter them after the ratings prompt date in the season manager)
          .mt-1.max-w-xs.pl-4(class='sm:mt-0')
            FormKit(
              type='toggle',
              placeholder='Enable Ratings',
              name='form.hasRatings',
              v-model='form.hasRatings',
              :on-value="1",
              :off-value="0"
            )
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-md.font-medium.text-gray-700(
            for='isLocked',
            class='sm:mt-px sm:pt-2'
          )
            | Lock Rosters
            .block.font-normal.mt-2.text-gray-500 If enabled, competition rosters are locked and cannot be changed.
          .mt-1.max-w-xs.pl-4(class='sm:mt-0')
            FormKit(
              type='toggle',
              placeholder='Is Locked',
              name='form.isLocked',
              v-model='form.isLocked',
              :on-value="1",
              :off-value="0"
            )
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-md.font-medium.text-gray-700(
            for='enableBirthCertificateCollection',
            class='sm:mt-px sm:pt-2'
          )
            | Enable Birth Certificate Collection
            .block.font-normal.mt-2.text-gray-500 Enables secure, digital collection and temporary storage of player birth certificates until validated by the league.
          .mt-1.max-w-xs.pl-4(class='sm:mt-0')
            FormKit(
              type='toggle',
              placeholder='Require Birth Certificate',
              name='form.enableBirthCertificateCollection',
              v-model='form.enableBirthCertificateCollection',
              :on-value="1",
              :off-value="0"
            )
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-md.font-medium.text-gray-700(
            for='neighborDivs',
            class='sm:mt-px sm:pt-2'
          )
            | Allow Neigbor Divisions
            .block.font-normal.mt-2.text-gray-500 If enabled, allows player assignments from neighboring divisions (but does not violate the one-assignment-per-competition rule).
          .mt-1.max-w-xs.pl-4(class='sm:mt-0')
            FormKit(
              type='toggle',
              placeholder='Allow Neigbor Divisions',
              name='form.neighborDivs',
              v-model='form.neighborDivs',
              :on-value="1",
              :off-value="0"
            )
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-md.font-medium.text-gray-700(
            for='form.requirePlayerPhotos',
            class='sm:mt-px sm:pt-2'
          )
            | Require Player Photos
            .block.font-normal.mt-2.text-gray-500 Allows upload of player photos to Affinity for ID cards.
          .mt-1.max-w-xs.pl-4(class='sm:mt-0')
            FormKit(
              type='toggle',
              placeholder='Require Player Photos',
              name='form.requirePlayerPhotos',
              v-model='form.requirePlayerPhotos',
              :on-value="1",
              :off-value="0"
            )
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-md.font-medium.text-gray-700(
            for='form.requireAdminPhotos',
            class='sm:mt-px sm:pt-2'
          )
            | Require Admin Photos
            .block.font-normal.mt-2.text-gray-500 Allows coaches to upload photos to Affinity for ID cards.
          .mt-1.max-w-xs.pl-4(class='sm:mt-0')
            FormKit(
              type='toggle',
              placeholder='Require Admin Photos',
              name='form.requireAdminPhotos',
              v-model='form.requireAdminPhotos',
              :on-value="1",
              :off-value="0"
            )
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-md.font-medium.text-gray-700(
            for='form.startDayOfWeek',
            class='sm:mt-px sm:pt-2'
          )
            | Start Day Of Week
            .block.font-normal.mt-2.text-gray-500 First day of week for the game schedule.
          .mt-1.max-w-xs.pl-4(class='sm:mt-0')
            FormKit(
              type='select',
              placeholder='Start Day Of Week',
              name='form.startDayOfWeek',
              v-model='form.startDayOfWeek',
              :options='{ 1: "Sunday", 2: "Monday", 3: "Tuesday", 4: "Wednesday", 5: "Thursday", 6: "Friday", 7: "Saturday" }'
            )
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-md.font-medium.text-gray-700(
            for='form.hasCoachFeedback',
            class='sm:mt-px sm:pt-2'
          )
            | Has Coach Feedback
            .block.font-normal.mt-2.text-gray-500 If enabled, enables coach feedback from parents for this competition.
          .mt-1.pl-4(class='sm:mt-0')
            FormKit(
              type='toggle',
              label='Has Coach Feedback',
              name='form.hasCoachFeedback',
              v-model='form.hasCoachFeedback',
              :on-value="1",
              :off-value="0"
            )
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-md.font-medium.text-gray-700(
            for='form.scopeID',
            class='sm:mt-px sm:pt-2'
          )
            | Coed Division Placement
            .block.font-normal.mt-2.text-gray-500
              | Female players whose age matches one of the selected values will be given a choice of a girls-only (G) division or the male/co-ed (B) division appropriate for their age group.
              div(class="text-sm italic")
                template(v-if="form.coreQuestion_coedAge_limitedToAges.length === 0") No values selected
                template(v-else) {{ form.coreQuestion_coedAge_limitedToAges.length }} value{{ form.coreQuestion_coedAge_limitedToAges.length === 1 ? "" : "s" }} selected
          .mt-1.max-w-xs.pl-4(class='sm:mt-0')
            div(class="border shadow-sm rounded-md" style="overflow:auto; max-height:10em; --fk-border:none;" data-test="coreQuestion_coedAge_limitedToAges")
              FormKit(
                type='checkbox',
                v-model='form.coreQuestion_coedAge_limitedToAges',
                :options='coreQuestion_coedAge_limitedToAgesOptions'
              )

      .mt-4(class='sm:border-t sm:border-gray-200')
        h3.text-xl.leading-6.font-medium.text-gray-900.pt-4.pb-2.pl-2
          | Scores &amp; Standings
      .grid.grid-cols-1.gap-12(class='lg:grid-cols-2')
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-md.font-medium.text-gray-700(
            for='form.useScores',
            class='sm:mt-px sm:pt-2'
          )
            | Use Scores
            .block.font-normal.mt-2.text-gray-500 Enable score input &amp; Tracking for this program.
          .mt-1.max-w-xs.pl-4(class='sm:mt-0')
            FormKit(
              type='toggle',
              placeholder='Use Scores',
              name='form.useScores',
              v-model='form.useScores',
              :on-value="1",
              :off-value="0"
            )
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-md.font-medium.text-gray-700(
            for='form.standingsDivnum',
            class='sm:mt-px sm:pt-2'
          )
            | Minimum Division for Standings
            .block.font-normal.mt-2.text-gray-500 Divisions equal to or greater than this value will show standings links.
            .mt-1.max-w-xs.pl-4(class='sm:mt-0')
            FormKit(
              type='select',
              name='form.standingsDivnum',
              v-model='form.standingsDivnum',
              :options='{ 0: "All Divisions", 5: "U5", 6: "U6", 7: "U7", 8: "U8", 9: "U9", 10: "U10", 11: "U11", 12: "U12", 13: "U13", 14: "U14", 15: "U15", 16: "U16", 17: "U17", 18: "U18", 99: "Disabled (No Standings)"}'
            )

        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-md.font-medium.text-gray-700(
            for='form.hideScores',
            class='sm:mt-px sm:pt-2'
          )
            | Hide Scores
            .block.font-normal.mt-2.text-gray-500 Hides game scores from schedule and standings module.
          .mt-1.max-w-xs.pl-4(class='sm:mt-0')
            FormKit(
              type='toggle',
              placeholder='Hide Scores',
              name='form.hideScores',
              v-model='form.hideScores',
              :on-value="1",
              :off-value="0"
            )
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-md.font-medium.text-gray-700(
            for='form.useAllGamesInStandings',
            class='sm:mt-px sm:pt-2'
          )
            | Use All Games In Standings
            .block.font-normal.mt-2.text-gray-500 If enabled, disregards pointsCount field when applying score transactions, eg. red cards, to standings.
          .mt-1.max-w-xs.pl-4(class='sm:mt-0')
            FormKit(
              type='toggle',
              placeholder='Use All Games In Standings',
              name='form.useAllGamesInStandings',
              v-model='form.useAllGamesInStandings',
              :on-value="1",
              :off-value="0"
            )
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-md.font-medium.text-gray-700(
            for='form.coachMayEnterGameScores',
            class='sm:mt-px sm:pt-2'
          )
            | Coach May Enter Game Scores
            .block.font-normal.mt-2.text-gray-500 If enabled, head- and co-coaches may enter scores for their own games.
          .mt-1.max-w-xs.pl-4(class='sm:mt-0')
            FormKit(
              type='toggle',
              placeholder='Coach May Enter Game Scores',
              name='form.coachMayEnterGameScores',
              v-model='form.coachMayEnterGameScores',
              :on-value="1",
              :off-value="0"
            )
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-md.font-medium.text-gray-700(
            for='form.winPoints',
            class='sm:mt-px sm:pt-2'
          )
            | Win Points
            .block.font-normal.mt-2.text-gray-500.mr-2 Number of standings points earned for a win
          .mt-1.max-w-xs(class='sm:mt-0')
            FormKit(
              type='number',
              placeholder='Win Points',
              name='form.winPoints',
              v-model='form.winPoints'
            )
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-md.font-medium.text-gray-700(
            for='form.losePoints',
            class='sm:mt-px sm:pt-2'
          )
            | Lose Points
            .block.font-normal.mt-2.text-gray-500.mr-2 Number of standings points for a loss
          .mt-1.max-w-xs(class='sm:mt-0')
            FormKit(
              type='number',
              placeholder='Lose Points',
              name='form.losePoints',
              v-model='form.losePoints'
              data-test="losePoints"
            )
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-md.font-medium.text-gray-700(
            for='form.tiePoints',
            class='sm:mt-px sm:pt-2'
          )
            | Tie Points
            .block.font-normal.mt-2.text-gray-500.mr-2 Number of standings points for a tie
          .mt-1.max-w-xs(class='sm:mt-0')
            FormKit(
              type='number',
              placeholder='Tie Points',
              name='form.tiePoints',
              v-model='form.tiePoints'
            )
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-md.font-medium.text-gray-700(
            for='form.maxPoints',
            class='sm:mt-px sm:pt-2'
          )
            | Max Points
            .block.font-normal.mt-2.text-gray-500 The maximum ADDITIONAL points per game
          .mt-1.max-w-xs(class='sm:mt-0')
            FormKit(
              type='number',
              name='form.maxPoints',
              v-model='form.maxPoints'
            )
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-md.font-medium.text-gray-700(
            for='form.maxGoals',
            class='sm:mt-px sm:pt-2'
          )
            | Max Goal Difference
            .block.font-normal.mt-2.text-gray-500 The maximum goal difference per game (0 for no maximum)
          .mt-1.max-w-xs(class='sm:mt-0')
            FormKit(
              type='number',
              name='form.maxGoals',
              v-model='form.maxGoals'
            )
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-md.font-medium.text-gray-700(
            for='form.scoretype',
            class='sm:mt-px sm:pt-2'
          )
            | Score Type
            .block.font-normal.mt-2.text-gray-500 Basis of additional points scored
          .mt-1.max-w-xs(class='sm:mt-0')
            FormKit(
              type='select',
              name='form.scoretype',
              v-model='form.scoretype',
              :options='{ none: "No Additional Points", difference: "Score based on overall goals", margin: "Score based on margin of victory" }'
            )
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-md.font-medium.text-gray-700(
            for='form.shutoutpoint',
            class='sm:mt-px sm:pt-2'
          )
            | Shut Out Point
            .block.font-normal.mt-2.text-gray-500 If enabled, gives an additional point for a shutout
          .mt-1.max-w-xs.pl-4(class='sm:mt-0')
            FormKit(
              type='toggle',
              label='Shut Out Point',
              name='form.shutoutpoint',
              v-model='form.shutoutpoint',
              :on-value="1",
              :off-value="0"
            )
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-md.font-medium.text-gray-700(
            for='form.zerotie',
            class='sm:mt-px sm:pt-2'
          )
            | Zero Tie
            .block.font-normal.mt-2.text-gray-500 Gives a point for a 0-0 tie
          .mt-1.pl-4(class='sm:mt-0')
            FormKit(
              type='toggle',
              label='Zero Tie',
              name='form.zerotie',
              v-model='form.zerotie',
              :on-value="1",
              :off-value="0"
            )
      .mt-4(class='sm:border-t sm:border-gray-200')
        h3.text-xl.leading-6.font-medium.text-gray-900.pt-4.pb-2.pl-2
          | Referees
      .grid.grid-cols-1.gap-12(class='lg:grid-cols-2')
        div(
          class='sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-md.font-medium.text-gray-700(
            for='form.maxRefRequests',
            class='sm:mt-px sm:pt-2'
          )
            | Maximum Self-Scheduler Referee Requests
            .block.font-normal.mt-2.text-gray-500 Maximum number of self-scheduling requests a referee may submit for a single calendar date. Does not count confirmed assignments. Blank or zero means unlimited.
          div(class='mt-2 max-w-24')
            FormKit(
              type='number',
              name='form.maxRefRequests',
              v-model='form.maxRefRequests'
              :validation="[['min', 0], ['max', 255]]"
              data-test="maxRefRequests"
            )
        div(class='sm:border-t sm:border-gray-200 sm:pt-5')
          label.block.text-md.font-medium.text-gray-700(
            class='sm:mt-px sm:pt-2'
          )
            | Referees Self Schedule Future Cutoff Date
            .block.font-normal.mt-2.text-gray-500
              div Limit the dates self-scheduled referee assignments can be made by setting a cutoff some days into the future. For example:
              div(
                style="display:grid; grid-template-columns: max-content 1fr;"
                class="my-2 w-full rounded-md border [&>div]:p-1"
              )
                div(class="border-b border-r") Cutoff
                div(class="border-b") Meaning
                div(class="border-b border-r") 0 days
                div(class="border-b") Can only self-schedule for games occurring today
                div(class="border-b border-r") 1 day
                div(class="border-b") Can only self-schedule for games occurring today and tomorrow
                div(class="border-b border-r") 2 days
                div(class="border-b") Can only self-schedule for games occurring between today's date and two days from now
                div(style="grid-column:-1/1;") ...and so on
          div(class="mt-3 max-w-24" style="--fk-margin-outer: none;")
            FormKit(
              type='number',
              name="Ref assignment cutoff"
              v-model='form.refAssignmentHorizonDaysFromNow',
              :validation="[['min', 0], ['max', 3650]]"
              data-test="refAssignmentHorizonDaysFromNow"
            )
          div(class="text-sm text-gray-500 mt-1") Leave blank for no cutoff (self-scheduling requests can be submitted for any future date)

        div(class='sm:border-t sm:border-gray-200 sm:pt-5')
          label.block.text-md.font-medium.text-gray-700(
            class='sm:mt-px sm:pt-2'
          )
            | Instantly Approve Referee Self-Scheduling Requests
            .block.font-normal.mt-2.text-gray-500
              div
                span(:class="['mr-1 text-green-700', form.refAssignmentsInstantlyApproved ? 'invisible' : '']")
                  FontAwesomeIcon(:icon="faCheck")
                span Disabled (default) - Referee self-scheduling requests are pending until approved by a referee scheduler.
              div
                span(:class="['mr-1 text-green-700', !form.refAssignmentsInstantlyApproved ? 'invisible' : '']")
                  FontAwesomeIcon(:icon="faCheck")
                span Enabled - Referee self-scheduling requests are automatically and instantly confirmed.
          .mt-4(class="pl-4" style="--fk-margin-outer: none;")
            FormKit(
              type='toggle',
              v-model='form.refAssignmentsInstantlyApproved',
              :on-value="1",
              :off-value="0"
              data-test="refAssignmentsInstantlyApproved"
            )

        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-md.font-medium.text-gray-700(
            for='form.refType',
            class='sm:mt-px sm:pt-2'
          )
            | Standard Display
            .block.font-normal.mt-2.text-gray-500 Control whether scheduler displays time slots with or without teams
          .mt-1.max-w-xs.pl-4(class='sm:mt-0')
            FormKit(
              type='select',
              placeholder='Referee Type',
              name='form.refType',
              v-model='form.refType',
              :options='{ 1: "Standard Display: Ref Scheduler Mode", 2: "Time Slot Display: Only display time slots (not teams)" }'
            )
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-md.font-medium.text-gray-700(
            for='form.refCanCancel',
            class='sm:mt-px sm:pt-2'
          )
            | Referees Can Cancel
            .block.font-normal.mt-2.text-gray-500 If enabled, referees can cancel their own approved assignments.
          .mt-1.max-w-xs.pl-4(class='sm:mt-0')
            FormKit(
              type='toggle',
              placeholder='Referees Can Cancel',
              name='form.refCanCancel',
              v-model='form.refCanCancel',
              data-test="refCanCancel"
              :on-value="1",
              :off-value="0"
            )
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-md.font-medium.text-gray-700(
            for='form.limitRefAccess',
            class='sm:mt-px sm:pt-2'
          )
            | Limit Referee Access
            .block.font-normal.mt-2.text-gray-500 If enabled, only referee volunteers can access the scheduler. If false, any user can.
          .mt-1.max-w-xs.pl-4(class='sm:mt-0')
            FormKit(
              type='toggle',
              placeholder='Limit Referee Access',
              name='form.limitRefAccess',
              v-model='form.limitRefAccess',
              data-test="limitRefAccess"
              :on-value="1",
              :off-value="0"
            )
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-md.font-medium.text-gray-700(
            for='form.showRefSchedule',
            class='sm:mt-px sm:pt-2'
          )
            | Show Referee Schedule
            .block.font-normal.mt-2.text-gray-500 If enabled, displays the referee schedule to the public. If false, only to referee schedulers.
          .mt-1.max-w-xs.pl-4(class='sm:mt-0')
            FormKit(
              type='toggle',
              placeholder='Show Referee Schedule',
              name='form.showRefSchedule',
              v-model='form.showRefSchedule',
              data-test="showRefSchedule"
              :on-value="1",
              :off-value="0"
            )
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-md.font-medium.text-gray-700(
            for='form.hasRefScheduling',
            class='sm:mt-px sm:pt-2'
          )
            | Has Referee Scheduling
            .block.font-normal.mt-2.text-gray-500 If enabled, enables referee scheduling for this competition.
          .mt-1.max-w-xs.pl-4(class='sm:mt-0')
            FormKit(
              type='toggle',
              placeholder='Has Referee Scheduling',
              name='form.hasRefScheduling',
              v-model='form.hasRefScheduling',
              :on-value="1",
              :off-value="0"
            )
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-md.font-medium.text-gray-700(
            for='form.refSchedulerEmail',
            class='sm:mt-px sm:pt-2'
          )
            | Referee Scheduler Email
            .block.font-normal.mt-2.text-gray-500 The email address associated with referee scheduling for this competition. If null, defaults to system-wide value.
          .mt-1.max-w-xs.pl-4(class='sm:mt-0')
            FormKit(
              type='text',
              placeholder='Referee Scheduler Email',
              name='form.refSchedulerEmail',
              data-test="refSchedulerEmail"
              v-model='form.refSchedulerEmail'
            )
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-md.font-medium.text-gray-700(
            for='form.allowPastRefScheduling',
            class='sm:mt-px sm:pt-2'
          )
            | Allow Past Referee Scheduling
            .block.font-normal.mt-2.text-gray-500 Allows referees to self-schedule for games in the past.
          .mt-1.max-w-xs.pl-4(class='sm:mt-0')
            FormKit(
              type='toggle',
              placeholder='Allow Past Referee Scheduling',
              name='form.allowPastRefScheduling',
              v-model='form.allowPastRefScheduling',
              data-test="allowPastRefScheduling"
              :on-value="1",
              :off-value="0"
            )
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-md.font-medium.text-gray-700(
            for='form.refCancelHours',
            class='sm:mt-px sm:pt-2'
          )
            | Referee Cancellation Deadline
            .block.font-normal.mt-2.text-gray-500 The number of hours before a game where a ref may submit a cancellation request.
          .mt-1.max-w-xs.pl-4(class='sm:mt-0')
            FormKit(
              type='number',
              placeholder='Referee Cancellation Deadline',
              name='form.refCancelHours',
              data-test="refCancelHours"
              v-model='form.refCancelHours'
              :validation="[['required'], ['min', 0], ['max', 255]]"
              validationLabel="Referee Cancellation Deadline"
            )
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-md.font-medium.text-gray-700(
            for='form.disableVPAssign',
            class='sm:mt-px sm:pt-2'
          )
            | Disable Volunteer Points Assignment
            .block.font-normal.mt-2.text-gray-500 If enabled, refs cannot assign volunteer points earned from this competition and they only may be manually assigned
          .mt-1.max-w-xs.pl-4(class='sm:mt-0')
            FormKit(
              type='toggle',
              placeholder='Disable Volunteer Points Assignment',
              name='form.disableVPAssign',
              v-model='form.disableVPAssign',
              data-test="disableVpAssign"
              :on-value="1",
              :off-value="0"
            )
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-md.font-medium.text-gray-700(
            for='form.creditAssignmentTeamScope',
            class='sm:mt-px sm:pt-2'
          )
            | Credit Assignment Team Scope
            .block.font-normal.mt-2.text-gray-500 Scope requirements for the teams to which refs can manually assign ref credits.
          .mt-1.max-w-xs.pl-4(class='sm:mt-0')
            FormKit(
              type='select',
              placeholder='Credit Assignment Team Scope',
              name='form.creditAssignmentTeamScope',
              v-model='form.creditAssignmentTeamScope',
              :options='{ 1: 1, 2: 2, 3: 3, 4: 4 }'
            )

        div(class='sm:border-t sm:border-gray-200 sm:pt-5')
          label.block.text-md.font-medium.text-gray-700(
            class='sm:mt-px sm:pt-2'
          )
            | Ref Scheduler Admin - Ignore Risk Status
            .block.font-normal.mt-2.text-gray-500
              div
                span(:class="['mr-1 text-green-700', form.refSchedulerAdminModeIgnoreRiskStatus ? 'invisible' : '']")
                  FontAwesomeIcon(:icon="faCheck")
                span Disabled (default) - Admin mode referee scheduling will take risk status into consideration when determining which users can be referees.
              div
                span(:class="['mr-1 text-green-700', !form.refSchedulerAdminModeIgnoreRiskStatus ? 'invisible' : '']")
                  FontAwesomeIcon(:icon="faCheck")
                span Enabled - Admin mode referee scheduling will not consider risk status when determing which users may be referees.
          .mt-4(class="pl-4" style="--fk-margin-outer: none;")
            FormKit(
              type='toggle',
              v-model='form.refSchedulerAdminModeIgnoreRiskStatus',
              :on-value="1",
              :off-value="0"
              data-test="refSchedulerAdminModeIgnoreRiskStatus"
            )

      .mt-4(class='sm:border-t sm:border-gray-200')
        h3.text-xl.leading-6.font-medium.text-gray-900.pt-4.pb-2.pl-2
          | Program Payments
      .grid.grid-cols-1.gap-12(class='lg:grid-cols-2')
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-md.font-medium.text-gray-700(
            for='form.hasPayments',
            class='sm:mt-px sm:pt-2'
          )
            | Has Payments
            .block.font-normal.mt-2.text-gray-500 Enable program-level team payments;
          .mt-1.pl-4(class='sm:mt-0')
            FormKit(
              type='toggle',
              label='Has Payments',
              name='form.hasPayments',
              v-model='form.hasPayments',
              :on-value="1",
              :off-value="0"
            )
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-md.font-medium.text-gray-700(
            for='form.excludeDate',
            class='sm:mt-px sm:pt-2'
          )
            | Exclude Date
            .block.font-normal.mt-2.text-gray-500 If specified, any team-level transactions before this date will not be counted toward the team ledger.
          .mt-1.max-w-xs.pl-4(class='sm:mt-0')
            FormKit(
              type='date',
              placeholder='Exclude Date',
              name='form.excludeDate',
              v-model='form.excludeDate'
            )
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-md.font-medium.text-gray-700(
            for='form.hasAccounting',
            class='sm:mt-px sm:pt-2'
          )
            | Has Accounting
            .block.font-normal.mt-2.text-gray-500 Enables team-level accounting administration tools.
          .mt-1.max-w-xs.pl-4(class='sm:mt-0')
            FormKit(
              type='toggle',
              placeholder='Has Accounting',
              name='form.hasAccounting',
              v-model='form.hasAccounting',
              :on-value="1",
              :off-value="0"
            )
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-md.font-medium.text-gray-700(
            for='form.paymentAmount',
            class='sm:mt-px sm:pt-2'
          )
            | Payment Amount
            .block.font-normal.mt-2.text-gray-500 Set all team payments for this program to a fixed aount
          .mt-1.max-w-xs(class='sm:mt-0')
            FormKit(
              type='number',
              name='form.paymentAmount',
              v-model='form.paymentAmount',
              step='0.01'
            )
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-md.font-medium.text-gray-700(
            for='form.paymentDesc',
            class='sm:mt-px sm:pt-2'
          )
            | Payment Description
            .block.font-normal.mt-2.text-gray-500 Cosmetic string description of payments to be displayed on the competition payment page
          .mt-1.max-w-xs.pl-4(class='sm:mt-0')
            FormKit(
              type='text',
              placeholder='System Default',
              name='form.paymentDesc',
              v-model='form.paymentDesc'
            )

        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-md.font-medium.text-gray-700(
            for='form.gatewayID',
            class='sm:mt-px sm:pt-2'
          )
            | Merchant Account
            .block.font-normal.mt-2.text-gray-500 Merchant account to be used for registration and team payments in this program
          .mt-1.max-w-xs.pl-4(class='sm:mt-0')
            FormKit(
              type='select',
              placeholder='Payment gateway',
              :options='paymentGatewayOptions',
              name='form.gatewayID',
              v-model='form.gatewayID'
            )

        div(
          style="--fk-margin-outer:none;"
          class='sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          div
            div(
              class='block text-md font-medium text-gray-700 sm:mt-px sm:pt-2'
            )
              | ACH Payments
            div(class="font-normal mt-2 text-gray-500 border rounded-md p-1")
              div(style="display: inline-grid; grid-template-columns: min-content auto;")
                div(class="p-1 flex items-center gap-2")
                  input(
                    type="radio"
                    class="transition"
                    name="achEnabled"
                    id="achEnabled-false"
                    data-test="achEnabled-false"
                    :value="0"
                    v-model="form.achEnabled"
                  )
                label(for="achEnabled-false" class="p-1")
                  | ACH payments disabled
                div(class="p-1 flex items-center gap-2")
                  input(
                    type="radio"
                    class="transition"
                    name="achEnabled"
                    id="achEnabled-true"
                    data-test="achEnabled-true"
                    :value="1"
                    v-model="form.achEnabled"
                  )
                label(for="achEnabled-true" class="p-1")
                  | ACH payments enabled

              div(
                v-if="form.achEnabled"
                class="font-normal mt-2 text-gray-500 px-1"
              )
                div(class="block font-normal text-gray-500")
                  p As an option, you may require payments to be made via ACH Direct Debit for fees greater than or equal to this value, since ACH Debit fees are lower than credit card fees.
                  p Leave blank if you do not want to require ACH Debit payments.
                div(class="my-2 max-w-48")
                  FormKit(
                    type='number',
                    v-model='form.requireAchPaymentWhenFeeMeetsOrExceeds'
                    validationLabel="Value"
                    :validation=[
                      ["min", 0]
                    ]
                    step=".01"
                    data-test="requireAchPaymentWhenFeeMeetsOrExceeds"
                    id="requireAchPaymentWhenFeeMeetsOrExceeds"
                  )

      .mt-4(class='sm:border-t sm:border-gray-200')
        h3.text-lg.leading-6.font-medium.text-gray-900.pt-4.pb-2.pl-2
          | Program Host
      .grid.grid-cols-1.gap-12(class='lg:grid-cols-2')
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-md.font-medium.text-gray-700(
            for='form.isHost',
            class='sm:mt-px sm:pt-2'
          )
            | Is Host
            .block.font-normal.mt-2.text-gray-500 Indicates that this competition is the master competition for an inter-league competition.
          .mt-1.max-w-xs.pl-4(class='sm:mt-0')
            FormKit(
              type='toggle',
              placeholder='Is Host',
              name='form.isHost',
              v-model='form.isHost',
              :on-value="1",
              :off-value="0"
            )
      .mt-4(class='sm:border-t sm:border-gray-200')
        h3.text-xl.leading-6.font-medium.text-gray-900.pt-4.pb-2.pl-2
          | Display Configurations
      .grid.grid-cols-1.gap-12(class='lg:grid-cols-2')
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-md.font-medium.text-gray-700(
            for='form.showFamilyRosters',
            class='sm:mt-px sm:pt-2'
          )
            | Show Family Rosters
            .block.font-normal.mt-2.text-gray-500 If enabled, families may view team rosters. If false, families only see their own player assignments and only coaches and above can see rosters.
          .mt-1.max-w-xs.pl-4(class='sm:mt-0')
            FormKit(
              type='toggle',
              placeholder='Show Family Rosters',
              name='form.showFamilyRosters',
              v-model='form.showFamilyRosters',
              :on-value="1",
              :off-value="0"
            )
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-md.font-medium.text-gray-700(
            for='form.showTeamsOnDashboards',
            class='sm:mt-px sm:pt-2'
          )
            | Show Teams On Dashboards
            .block.font-normal.mt-2.text-gray-500 If enabled, shows teams for this competition on the family profile and user dashboard pages.
          .mt-1.max-w-xs.pl-4(class='sm:mt-0')
            FormKit(
              type='toggle',
              placeholder='Show Teams On Dashboards',
              name='form.showTeamsOnDashboards',
              v-model='form.showTeamsOnDashboards',
              :on-value="1",
              :off-value="0"
            )
        div(
          class='sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5'
        )
          label.block.text-md.font-medium.text-gray-700(
            for='form.scopeID',
            class='sm:mt-px sm:pt-2'
          )
            | Program Scope
            .block.font-normal.mt-2.text-gray-500 Regiona / Area / Sectional / National
          .mt-1.max-w-xs.pl-4(class='sm:mt-0')
            FormKit(
              type='select',
              placeholder='Scope ID',
              name='form.scopeID',
              v-model='form.scopeID',
              :options='{ 1: "Regional", 2: "Area", 3: "Sectional", 4: "National" }'
            )

      .mt-4(class='sm:border-t sm:border-gray-200')
        h3.text-xl.leading-6.font-medium.text-gray-900.pt-4.pb-2.pl-2
          | Additional Program Sources for Team Assignments
        .grid.grid-cols-1.gap-8.px-2.py-4(class='lg:grid-cols-2' style="--fk-margin-outer:none;")
          div.flex.flex-col(class="border rounded-md p-2 shadow-sm")
            div.flex.flex-col.grow
              FormKit(
                type="select"
                multiple
                label="Available programs"
                :options="playerSourcingCompetitionsState.options.free.value"
                v-model="playerSourcingCompetitionsState.selected.free.value"
                outer-class="grow flex flex-col"
                inner-class="grow flex flex-col"
                wrapper-class="grow flex flex-col $reset"
                input-class="grow flex flex-col"
              )
            div.flex.justify-center.mt-2
              Btn2(type="button" class="p-2" @click="playerSourcingCompetitionsState.setFreeToBound()") Use selected
          div.flex.flex-col(class="border rounded-md p-2 shadow-sm")
            div.flex.flex-col.grow
              FormKit(
                type="select"
                multiple
                label="Sourcing players from..."
                :options="playerSourcingCompetitionsState.options.bound.value"
                v-model="playerSourcingCompetitionsState.selected.bound.value"
                outer-class="grow flex flex-col"
                inner-class="grow flex flex-col"
                wrapper-class="grow flex flex-col $reset"
                input-class="grow flex flex-col"
              )
            div.flex.justify-center.mt-2
              Btn2(type="button" class="p-2" @click="playerSourcingCompetitionsState.setBoundToFree()") Don't use selected
      div(class='sm:border-t sm:border-gray-200 sm:pt-5')
        TBtn(label='Update Competition Details', type='submit' data-test="submit2")
</template>

<script lang="ts">
import { AxiosErrorWrapper, axiosInstance } from 'src/boot/axios'
import {
  defineComponent,
  ref,
  computed,
  onMounted,
  watch,
  getCurrentInstance,
  Ref,
ComputedRef,
} from 'vue'
import { formatForDateInput } from 'src/helpers/formatDate'
import Seasons from 'src/components/Selectors/Seasons'
import { Guid, isCfNull, WithDefinite } from 'src/interfaces/InleagueApiV1'
import QuillEditor from 'src/components/UserInterface/quill-wrapper.vue'
import { Competition, StackPlayLevelListing } from "src/interfaces/InleagueApiV1"
import * as ilapi from "src/composables/InleagueApiV1"

import { parseIntOr, UiOption, useIziToast, VueNeverUnwrappable } from 'src/helpers/utils'
import { getCompetitionsOrFail, updateCompetition } from 'src/store/Competitions'
import { Btn2 } from "src/components/UserInterface/Btn2"
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { Client } from 'src/store/Client'

export default defineComponent({
  props: {
    competitionUID: {
      type: String,
      required: true,
    },
  },
  components: {
    Seasons,
    QuillEditor,
    Btn2,
    FontAwesomeIcon
  },
  setup(props) {
    type CompetitionForm = ExpandedCompetition & {coreQuestion_coedAge_limitedToAges: string[]}
    const form = ref ({} as CompetitionForm)
    const stackPlayLevelInfo = ref<StackPlayLevelListing>({})
    const iziToast = useIziToast();
    const coreQuestion_coedAge_limitedToAgesOptions : UiOption[] = (() => {
      const result : UiOption[] = []
      for (let i = 2; i <= 18; i++) {
        result.push({label: i.toString(), value: i.toString()})
      }
      return result;
    })();

    const allCompetitionsMap = ref<{[competitionUID: Guid]: Competition}>({})
    const stackPlayLevelOptions = computed(() => {
      const result: { value: string; label: string }[] = []
      for (const stackPlayLevelName of Object.keys(stackPlayLevelInfo.value)) {
        result.push({
          // guid
          value: stackPlayLevelInfo.value[stackPlayLevelName].Key,
          // human readable
          label: stackPlayLevelInfo.value[stackPlayLevelName].Name,
        })
      }
      result.sort((l, r) => (l.label < r.label ? -1 : 1))
      return result
    })

    const paymentGatewayOptions = ref<UiOption[]>([])
    const customToolbar = ref([
      [{ font: [] }],
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      [{ size: ['small', false, 'large', 'huge'] }],
      ['bold', 'italic', 'underline', 'strike'],
      [
        { align: '' },
        { align: 'center' },
        { align: 'right' },
        { align: 'justify' },
      ],
      ['blockquote', 'code-block'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ color: [] }, { background: [] }],
      ['link', 'image', 'formula'],
      ['clean'],
    ])
    const ready = ref(false)

    const getCompetitionDetails = async () : Promise<CompetitionForm> => {
      // could cache playlevel info somewhere, it doesn't change
      stackPlayLevelInfo.value = await ilapi.getStackSportsPlayLevels(axiosInstance)
      const competition = await ilapi.getCompetition(axiosInstance, {competitionUID: props.competitionUID, expand: ["sourceCompetitionUIDs"]});

      mungeCommaDelimitedLists()
      mungeDates()

      return competition as CompetitionForm;

      function mungeCommaDelimitedLists() : void {
        // list to array, and filter any junk just in case
        const a = Array.isArray(competition.coreQuestion_coedAge_limitedToAges)
          ? competition.coreQuestion_coedAge_limitedToAges
          : competition.coreQuestion_coedAge_limitedToAges.split(",");
        competition.coreQuestion_coedAge_limitedToAges = a.filter((v: any) => parseIntOr(v, null) != null);
      }

      function mungeDates() : void {
        if (isCfNull(competition.excludeDate)) {
          // nothing to do
          return
        }
        else {
          competition.excludeDate = formatForDateInput(competition.excludeDate)
        }
      }
    }

    const updateCompetitionDetails = async () => {
      try {
        await updateCompetition(form.value);
        await getCompetitionDetails()
        iziToast.success({
          message: `Program administration has been succesfully saved.`,
        })
      }
      catch (e) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(e);
      }
    }

    const playerSourcingCompetitionsState = ref(PlayerSourcingCompetitionsState())

    const initValues = async () => {
        allCompetitionsMap.value = await (async () => {
        const result : {[competitionUID: Guid]: Competition} = {};
        const competitions = (await getCompetitionsOrFail( { includeDisabled : true } )).value;
        for (const comp of competitions) {
          result[comp.competitionUID] = comp;
        }
        return result;
      })();
      const competitionDetails = await getCompetitionDetails()
      const paymentGateways = await ilapi.getPaymentGateways(axiosInstance)
      form.value = competitionDetails

      paymentGatewayOptions.value = paymentGateways.map(v => {
        return {
          label: v.accountName,
          value: v.clientGatewayID,
        }
      })

      playerSourcingCompetitionsState.value = PlayerSourcingCompetitionsState({allCompetitionsMap: allCompetitionsMap, liveCompetitionFormRef: form});
      ready.value = true
    }

    watch(
      () => props.competitionUID,
      async () => {
        await initValues()
      }
    )
    onMounted(async () => {
      await initValues()
    })

    return {
      form,
      ready,
      stackPlayLevelInfo,
      stackPlayLevelOptions,
      paymentGatewayOptions,
      customToolbar,
      playerSourcingCompetitionsState,
      updateCompetitionDetails,
      coreQuestion_coedAge_limitedToAgesOptions,
      faCheck,
    }
  },
})

type ExpandedCompetition = WithDefinite<Competition, "sourceCompetitionUIDs">

interface PlayerSourcingCompetitionsState {
  selected: {
    free: Ref<(string | Guid)[]>,
    bound: Ref<(string | Guid)[]>,
  },
  options: {
    free: ComputedRef<UiOption<(string | Guid)>[]>,
    bound: ComputedRef<UiOption<(string | Guid)>[]>
  },
  setFreeToBound: () => void,
  setBoundToFree: () => void,
}

/**
 * state and methods to manage selecting/deselecting "source competition UIDs"
 * constructible-from-nothing for a safe in-template interface, for use during component initialization but before onMounted
 * @param liveCompetitionFormRef The wrapped `Competition` object will be mutated by this method, when binding/freeing `sourceCompetitionUIDs` values
 */
function PlayerSourcingCompetitionsState(args?: {allCompetitionsMap: Ref<{[competitionUID: Guid]: Competition}>, liveCompetitionFormRef: Ref<ExpandedCompetition>}) : VueNeverUnwrappable<PlayerSourcingCompetitionsState> {
  if (args) {
    return realState(args);
  }
  else {
    return safeNilState();
  }
  function safeNilState() {
    return VueNeverUnwrappable<PlayerSourcingCompetitionsState>({
      selected: {
        free: ref([]),
        bound: ref([])
      },
      options: {
        free: computed(() => []),
        bound: computed(() => [])
      },
      setFreeToBound: () => void 0,
      setBoundToFree: () => void 0,
    })
  }
  function realState({allCompetitionsMap, liveCompetitionFormRef}: Exclude<typeof args, undefined>) {
    function commonUiOptionInPlaceSort(v: UiOption<any>[]) : void {
      v.sort((l,r) => l.label < r.label ? -1 : 1)
    }
    const selected : PlayerSourcingCompetitionsState["selected"] = {
      free: ref([]),
      bound: ref([]),
    }
    const options : PlayerSourcingCompetitionsState["options"] = {
      free: computed<UiOption<string | Guid>[]>(() => {
        if (Object.keys(allCompetitionsMap.value).length === 0 || !liveCompetitionFormRef.value) {
          return [];
        }
        const currentSourceCompetitionUIDs = new Set(liveCompetitionFormRef.value.sourceCompetitionUIDs);
        const result : UiOption<Guid>[] = [];
        for (const competitionUID of Object.keys(allCompetitionsMap.value)) {
          if (competitionUID === liveCompetitionFormRef.value.competitionUID) {
            // a competition cannot be selected to serve as a source for itself
            continue;
          }
          const comp = allCompetitionsMap.value[competitionUID];
          if (!currentSourceCompetitionUIDs.has(comp.competitionUID)) {
            result.push({
              value: comp.competitionUID,
              label: comp.competition,
            })
          }
        }
        commonUiOptionInPlaceSort(result);
        return result;
      }),
      bound: computed<UiOption<Guid>[]>(() => {
        if (!liveCompetitionFormRef.value) {
          return [];
        }
        const result : UiOption<Guid>[] = [];
        for (const competitionUID of liveCompetitionFormRef.value.sourceCompetitionUIDs) {
          result.push({
            // We always expect `allCompetitionsMap` to contain a key for the current competitionUID
            // Not finding it is a bug (competitions listing and this competitin's sourceCompetitionUIDs are out of sync? something got deleted and didn't cascade?)
            // In the failure case where we don't find it, we can't do much, so we just fallback to the competitionUID, which will be ugly in the UI but won't crash
            label: allCompetitionsMap.value[competitionUID]?.competition || competitionUID,
            value: competitionUID,
          })
        }
        commonUiOptionInPlaceSort(result);
        return result;
      })
    }
    const setFreeToBound = () => {
      liveCompetitionFormRef.value.sourceCompetitionUIDs.push(...selected.free.value);
      selected.free.value = [];
    }
    const setBoundToFree = () => {
      // repeated linear scan, ok, shouldn't be more than like 5 values
      liveCompetitionFormRef.value.sourceCompetitionUIDs = liveCompetitionFormRef.value.sourceCompetitionUIDs.filter((v) => {
        // any values selected in the "bound" category will be discarded
        return !selected.bound.value.includes(v)
      })
      selected.bound.value = [];
    }
    return VueNeverUnwrappable<PlayerSourcingCompetitionsState>({
      selected,
      options,
      setFreeToBound,
      setBoundToFree
    })
  }
}
</script>
