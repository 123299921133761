import { FormKit, FormKitMessages } from "@formkit/vue"
import { makeTabDefMapping, TabDef, Tabs } from "src/components/UserInterface/Tabs"
import { assertIs, assertNonNull, assertTruthy, cssNamedGridAreas, exhaustiveCaseGuard, FK_nodeRef, forceCheckedIndexedAccess, JS_DayOfWeek, nextGlobalIntlike, noAvailableOptions, parseIntOr, parseIntOrFail, rangeExc, requireNonNull, sortBy, sortByDayJS, TailwindBreakpoint, UiOption, UiOptions, useWindowSize, vOptT, vReqT, VueGenericRefKludge } from "src/helpers/utils"
import { Datelike, DateTimelike, Division, Guid, Integerlike } from "src/interfaces/InleagueApiV1"
import { computed, defineComponent, ref, UnwrapRef } from "vue"
import { teamDesignationAndMaybeName } from "./GameScheduler.shared"
import dayjs, { Dayjs } from "dayjs"
import { Btn2 } from "src/components/UserInterface/Btn2"
import { ReactiveReifiedPromise, ReifiedPromise } from "src/helpers/ReifiedPromise"
import { SoccerBall } from "src/components/SVGs"
import { DAYJS_FORMAT_HTML_DATE, dayjsOr } from "src/helpers/formatDate"
import { axiosAuthBackgroundInstance } from "src/boot/AxiosInstances"
import { Field } from "src/composables/InleagueApiV1"
import { CreatePracticeSlotArgs_SlotDef, findSlotAssignmentUserCandidates, FindSlotAssignmentUserCandidatesResult, getPracticeSlotAssignmentUserSeasonInfo, listPracticeSlotAssignments, PracticeSchedulerSeason, PracticeSchedulerTeamInfo, PracticeSlotAssignment, PracticeSlotAssignmentData1 } from "./PracticeScheduler.io"
import { User } from "src/store/User"
import { PracticeSlotsAssignmentsReportStore, PracticeSlotsAssignmentsReportElemen } from "./PracticeScheduler.report"
import { PracticeSchedulerPermitsMgrElement, PracticeSchedulerPermitsMgrStore } from "./PracticeSchedulerPermitsMgr"
import { ExtractFormSlots } from "./PracticeScheduler.form"
import { AutoModal, DefaultModalController_r } from "src/components/UserInterface/Modal"
import { EditDaysPerWeekModal } from "./EditDaysPerWeekModal"
import { SelectMany } from "src/components/RefereeSchedule/SelectMany"
import { FkDynamicValidation } from "src/helpers/FkUtils"

export const PracticeSchedulerActions = defineComponent({
  props: {
    mode: vReqT<"admin" | "self">(),
    /**
     * null means "no-authz" (no permission to create slots)
     */
    createPracticeSlotsForm: vReqT<CreatePracticeSlotsFormStore | null>(),
    practiceSlotsAssignmentData: vReqT<SlotSchedulingDataStore>(),
    selectedTabId: vReqT<PracticeSchedulerActionTabID>(),
    canMakeNonSelfSlotAssignments: vReqT<boolean>(),
    reportingStore: vReqT<PracticeSlotsAssignmentsReportStore | null>(),
    practiceSchedulerPermitsMgrStore: vReqT<PracticeSchedulerPermitsMgrStore | null>(),
    viewInfo: vReqT<{seasonUID: Guid, fieldUIDs: Guid[], dateFrom: Dayjs, dateTo: Dayjs}>(),
  },
  emits: {
    createPracticeSlots: () => true,
    "update:seasonUID": (_seasonUID: Guid) => true,
    loadSlotsForAssignmentWorkflow: (_: {
      season: PracticeSchedulerSeason,
      userInfo: null | SlotAssignmentUserBinding,
      viewDateFrom: Datelike,
      viewDateTo: Datelike,
      onlyShowFieldsHavingSlots: boolean,
    }) => true,
    changeTab: (_: PracticeSchedulerActionTabID) => true,
  },
  setup(props, ctx) {
    const {
      timeStartHourOptions,
      timeStartMinuteOptions,
      gameLengthMinutesOptions,
    } = commonTimeOptions()

    // TODO: probably we can do better than "recompute all the slots on every relevant update" to find the start/end dates of what slots we will generate
    const generatedSlotInfo = computed(() => {
      if (props.createPracticeSlotsForm) {
        const slots = ExtractFormSlots(props.createPracticeSlotsForm)

        if (slots.length === 0) {
          return null
        }

        slots.sort(sortByDayJS(reDayJSify))

        return {
          slots,
          start: reDayJSify(slots[0]),
          end: reDayJSify(slots[slots.length - 1]),
        }
      }
      else {
        return null
      }

      function reDayJSify(v: CreatePracticeSlotArgs_SlotDef) {
        return dayjs(`${v.startDate} ${v.startHr24}:${v.startMinute}`)
      }
    })

    /**
     * returns non-empty string if there is an error, otherwise null
     * The string can be tested for truthiness, and serves as an indicator of which case is the triggering one
     */
    const willCreateSlotsOutsideOfCurrentViewOptions = computed<string | null>(() => {
      if (!props.createPracticeSlotsForm) {
        return null
      }

      const form = props.createPracticeSlotsForm

      if (!form.seasonUID.selectedKey) {
        // form invalid
        return null
      }

      if (props.viewInfo.seasonUID !== form.seasonUID.selectedKey) {
        // creating for season X, looking at season Y
        return "Relevant View Options: Selected Season"
      }

      if (!form.fieldUID.selectedKey) {
        // form invalid
        return null
      }
      if (!props.viewInfo.fieldUIDs.find(fieldUID => fieldUID === form.fieldUID.selectedKey)) {
        // creating for Field F but not looking at F
        return "Relevant View Options: Selected Fields"
      }

      const slotTimeBounds = generatedSlotInfo.value
      if (!slotTimeBounds) {
        // form invalid
        return null
      }

      const left = slotTimeBounds.start.isBefore(props.viewInfo.dateFrom, "day")
      const right = slotTimeBounds.end.isAfter(props.viewInfo.dateTo, "day")
      if (left || right) {
        //                x  |------------------|  x
        //                ^  |------------------|  ^
        // creating here--|  | view window here |  |--creating here
        //
        return "Relevant View Options: Date Range"
      }

      return null
    })

    const howManySlotsToCreateOptions = (() => {
      const result : UiOption[] = []
      for (let i = 1; i <= 10; i++) {
        result.push({label: i.toString(), value: i.toString()})
      }
      return result;
    })()

    const createPracticeSlots_smLayout = cssNamedGridAreas(
      "seasonFieldDiv    seasonFieldDiv",
      "datetime          datetime",
      "radio             radio",
      "comment           comment",
    )

    const createPracticeSlots_mdLayout = cssNamedGridAreas(
      "seasonFieldDiv    radio",
      "datetime          datetime",
      "comment           comment",
    )

    const createPracticeSlots_lgLayout = cssNamedGridAreas(
      "seasonFieldDiv    datetime          radio",
      "comment           comment           comment",
    )

    const windowSize = useWindowSize()
    const createPracticeSlots_gridLayout = computed(() => {
      return windowSize.width < TailwindBreakpoint.lg ? createPracticeSlots_smLayout
        : windowSize.width < TailwindBreakpoint["2xl"] ? createPracticeSlots_mdLayout
        : createPracticeSlots_lgLayout;
    })

    const editDaysPerWeekModalController = (() => {
      return DefaultModalController_r<{form: CreatePracticeSlotsFormStore}>({
        title: () => <>
          <div>Generate Slots on these Days</div>
          <div class="my-2 border-b"/>
        </>,
        content: data => {
          if (!data) {
            return null
          }
          const form = data.form
          return <EditDaysPerWeekModal
            baseDate={form.initialWeekStart}
            repeatWeeks={form.repeatWeeks}
            repeatWeeksOptions={form.repeatWeeksOptions}
            daysOfWeekPerWeek={form.daysOfWeekPerWeek}
            startDateWeekOptions={form.startDateWeekOptions}
            initialWeekStart={form.initialWeekStart}
            onCancel={() => editDaysPerWeekModalController.close()}
            onCommit={args => {
              form.daysOfWeekPerWeek = args.daysOfWeekPerWeek
              form.repeatWeeks = args.repeatWeeks
              form.initialWeekStart = args.initialWeekStart
              editDaysPerWeekModalController.close()
            }}
          />
        }
      })
    })();

    const createPracticeSlotsTab = (() => {
      const AtLeastOneDivSelected = FkDynamicValidation(() => {
        const form = props.createPracticeSlotsForm
        if (!form) {
          return {status: "unknown"}
        }

        return form.divIDs.selectedKey.length === 0
          ? {status: "invalid", msg: "Select at least 1 division."}
          : {status: "valid"}
      })

      return () => {
        if (props.createPracticeSlotsForm === null) {
          return null
        }

        const form = props.createPracticeSlotsForm

        return (
          <div data-test="createPracticeSlots">
            <FormKit type="form" onSubmit={() => { ctx.emit("createPracticeSlots") }} actions={false}>
              <div style={`max-wdith:2000px; display:grid; grid-template-areas:${createPracticeSlots_gridLayout.value}; grid-gap:.5em 1em;`} class="text-sm">
                <div style="grid-area: datetime;">
                  <div style="display:grid; grid-template-columns: max-content 1fr; grid-gap:.5em; align-items:center;">
                    <div style="grid-column:1/-1;">
                      <div style="display:grid; grid-template-columns: max-content 1fr; grid-gap: .5em 1em; align-items:center;">
                        <span class="font-medium flex justify-end">Create</span>
                        <div class="flex items-center gap-2 justify-start">
                          <span class="w-1/3">
                            <FormKit
                              type="select"
                              v-model={form.slotCountPerDay}
                              options={howManySlotsToCreateOptions}
                              data-test="slotCountPerDay"
                            />
                          </span>
                          <span class="">practice slot(s)</span>
                        </div>

                        <span class="flex justify-end">starting at</span>
                        <div class="flex gap-2 max-w-64">
                          <div class="flex-grow"><FormKit type="select" v-model={form.startHour24} options={timeStartHourOptions} data-test="startHour24"/></div>
                          <div class="flex-grow"><FormKit type="select" v-model={form.startMinute} options={timeStartMinuteOptions} data-test="startMinute"/></div>
                        </div>

                        <span class="flex justify-end">duration of</span>
                        <div class="flex items-center justify-start gap-2">
                          <span class="w-1/3"><FormKit type="select" v-model={form.slotDurationMinutes} options={gameLengthMinutesOptions} data-test="slotDurationMinutes"/></span>
                          <span class="">minutes</span>
                        </div>
                        <div class="font-medium text-sm" style="justify-self:end; align-self:start;">Initial Week</div>
                        <div>
                          <div class="max-w-64">
                            <FormKit
                              type="select"
                              disabled={form.startDateWeekOptions.disabled}
                              options={form.startDateWeekOptions.options}
                              v-model={form.initialWeekStart}
                              placeholder="Select an initial week"
                              validation={[["required"]]}
                              data-test="initialWeekStart"
                              validationLabel="StartDate"
                            />
                          </div>
                        </div>
                        <div style="grid-column:-1/1;">
                          <label class="my-1 flex items-start gap-2">
                            <input style="margin-top: 2px;" type="radio" class="transition" name="repeatness" checked={!form.generateAsRecurrenceGroups} onInput={() => {form.generateAsRecurrenceGroups = false}}/>
                            Generate individual practice slots not associated with a recurring practice
                          </label>
                          <label class="my-1 flex items-start gap-2">
                            <input style="margin-top: 2px;" type="radio" class="transition" name="repeatness" checked={!!form.generateAsRecurrenceGroups} onInput={() => {form.generateAsRecurrenceGroups = true}}/>
                            Practice slots generated as recurring practices for repeated weekdays
                          </label>
                          <span class="flex-grow">
                            <FormKit
                              type="select"
                              disabled={form.repeatWeeksOptions.disabled}
                              v-model={form.repeatWeeks}
                              options={form.repeatWeeksOptions.options}
                              data-test="repeatWeeks"
                            />
                          </span>
                        </div>
                      </div>
                    </div>

                    <div style="grid-column:1/-1;">
                      <div>Days per week:</div>
                      <div class="flex items-center gap-2">
                        <Btn2 class="px-2 py-1" data-test="editDaysPerWeek-button" onClick={() => editDaysPerWeekModalController.open({form})}>Edit</Btn2>
                        <div>{daysPerWeekUiString(form.daysOfWeekPerWeek, form.initialWeekStart)}</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div style="grid-area: seasonFieldDiv">
                  <div style="display:grid; grid-template-columns: max-content 1fr; grid-gap: .5em 1em; align-items:start;">
                    <div class="font-medium text-sm">Season</div>
                    <FormKit
                      type="select"
                      disabled={form.seasonOptions.disabled}
                      options={form.seasonOptions.options}
                      v-model={form.seasonUID.selectedKey}
                      placeholder="Choose a Season"
                      validation={[["required"]]}
                      validationLabel="Season"
                      data-test="seasonUID"
                      onInput={(value: any) => {
                        if (form.seasonUID.selectedKey === value) {
                          return
                        }
                        form.seasonUID.selectedKey = value
                        form.recomputeStartWeekOptions(form.seasonUID.selectedObj)
                        form.recomputeRepeatWeeksOptions(form.seasonUID.selectedObj)
                      }}
                    />
                    <div class="font-medium text-sm">Field</div>
                    <div>
                      <FormKit
                        type="select"
                        disabled={form.fieldOptions.disabled}
                        options={form.fieldOptions.options}
                        v-model={form.fieldUID.selectedKey}
                        placeholder="Choose a Field"
                        validation={[["required"]]}
                        validationLabel="Field"
                        data-test="fieldUID"
                        onInput={(value: any) => {
                          if (form.fieldUID.selectedKey === value) {
                            return
                          }
                          form.fieldUID.selectedKey = value
                        }}
                      />
                    </div>
                    <div class="font-medium text-sm">Max # of Teams</div>
                    <div>
                      <FormKit
                        type="number"
                        v-model={form.allowableTeamCount}
                        validation={[["required"], ["min", 0]]}
                        validationLabel="Max # of Teams"
                      />
                    </div>
                    <div class="font-medium text-sm">Divisions</div>
                    <div>
                      <div style="max-width: var(--fk-max-width-input);" class="border rounded-md">
                        <div data-test="divIDs-selectMany" style="max-height: 10em; overflow-y: auto;">
                          <SelectMany
                            options={form.divisionOptions.options}
                            selectedKeys={form.divIDs.selectedKey}
                            offerAllOption={true}
                            onChecked={fresh => { form.divIDs.selectedKey = fresh }}
                          />
                        </div>
                        <AtLeastOneDivSelected classesIfHasErrors="px-2 mb-1"/>
                      </div>
                    </div>
                    <div class="font-medium text-sm">Visible On or After</div>
                    <div>
                      <FormKit
                        type="datetime-local"
                        v-model={form.visibleOnOrAfter}
                        data-test="visibleOnOrAfter"
                      />
                      {form.visibleOnOrAfter
                        ? <a class="il-link text-sm" href="javascript:void(0)" onClick={() => { form.visibleOnOrAfter = "" }}>Clear</a>
                        : null}
                      <div style="max-width: var(--fk-max-width-input);">
                        <p class="my-1">Practice slots won't be shown or assignable to coaches before this date.</p>
                        <p class="my-1">Leave blank for 'immediately visible'.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex items-center gap-2">
                <div>
                  <Btn2 type="submit" class="mt-2 px-1 py-2" data-test="createSlots-button">Create Slots</Btn2>
                  {(() => {
                    const slotInfo = generatedSlotInfo.value
                    if (slotInfo === null || slotInfo.slots.length === 0) {
                      return null
                    }

                    const nSlots = slotInfo.slots.length === 1 ? "1 slot" : `${slotInfo.slots.length} slots`;
                    const slotCountPerDay = parseIntOr(form.slotCountPerDay, null)
                    const daysPerWeek = form.daysOfWeekPerWeek.length
                    const totalWeeks = (() => {
                      const v = parseIntOr(form.repeatWeeks, null)
                      return v === null ? null : (v + 1); // add 1 for "total weeks" (repeat weeks of zero means total weeks of 1)
                    })();

                    if (slotCountPerDay === null || daysPerWeek === 0 || totalWeeks === null) {
                      return null
                    }

                    const week = totalWeeks === 1 ? "week" : "weeks";

                    return <div class="text-sm mt-2">
                      <span>Will generate {nSlots} </span>
                      <span>({slotCountPerDay} per day &times; </span>
                      <span>{totalWeeks} {week} &times; </span>
                      <span>{daysPerWeek} days per week) </span>
                    </div>
                  })()}
                </div>
                {willCreateSlotsOutsideOfCurrentViewOptions.value
                  ? <div class="text-sm">
                      <div>Note: will create slots outside of the current view options</div>
                      <div>{willCreateSlotsOutsideOfCurrentViewOptions.value}</div>
                      <div>Generated slots will span {generatedSlotInfo.value?.start.format("MM/DD/YYYY")} - {generatedSlotInfo.value?.end.format("MM/DD/YYYY")}</div>
                  </div>
                  : null}
              </div>
            </FormKit>
          </div>
        )

      }
    })();

    const assignCoachTab = (() => {
      const defaultStartDate = dayjs().subtract(1, "week")
      const onlyShowFieldsHavingSlots = ref(true) // probably always true in "assign a coach to a practice slot" mode

      const {dateFrom, dateTo} = (() => {
        const season = props.practiceSlotsAssignmentData.phase1.seasonUID.selectedObj
        const v = defaultSeasonDateRange(season)
        const dateFrom = ref(v.dateFrom)
        const dateTo = ref(v.dateTo)
        return {dateFrom, dateTo}
      })()

      const effectiveDateFromTo = (season: PracticeSchedulerSeason) => {
        return {
          dateFrom: dateFrom.value // user supplied
            || defaultStartDate.format(DAYJS_FORMAT_HTML_DATE), // default
          dateTo: dateTo.value // user supplied
            || dayjsOr(dateFrom.value)?.add(season.seasonWeeks || 8, "weeks").format(DAYJS_FORMAT_HTML_DATE) // datefrom + (seasonwWeeks or 8 if no seasonweeks)
            || defaultStartDate.add(8, "weeks").format(DAYJS_FORMAT_HTML_DATE), // default start + 8 weeks
        }
      }

      const updateDatesFromSeason = (season: PracticeSchedulerSeason) : void => {
        const v = defaultSeasonDateRange(season)
        dateFrom.value = v.dateFrom
        dateTo.value = v.dateTo
      }

      const tryInitPhase2AndUpdateView = async () : Promise<void> => {
        const form = props.practiceSlotsAssignmentData
        const userSeason = await form.selectedUserSeasonInfo.tryInit()
        if (!userSeason) {
          if (props.practiceSlotsAssignmentData.mode === "self") {
            // in self mode, can't do anything without having init'd phase2
            return
          }
        }

        const season = form.phase1.seasonUID.selectedObj

        if (!season) {
          return // shouldn't happen
        }

        const userinfo : {ok : false} | {ok: true, user: null | SlotAssignmentUserBinding} = (() => {
          const userlike = form.phase1.selectedUser.value.userlike
          const teamInfo = userSeason?.teamID.selectedObj

          if (!userlike || !teamInfo) {
            if (props.practiceSlotsAssignmentData.mode === "self") {
              // in self mode, need to have selected a user (well, that's implied...) and a team
              return {ok: false} as const
            }
            else if (props.practiceSlotsAssignmentData.mode === "admin") {
              // in admin mode, we might not have selected a user/team yet, and that's ok
              return {ok: true, user: null} as const
            }
            else {
              exhaustiveCaseGuard(props.practiceSlotsAssignmentData.mode)
            }
          }
          else {
            return {ok: true, user: {userlike, teamInfo: teamInfo}} as const
          }
        })()

        if (!userinfo.ok) {
          return
        }

        const {dateFrom, dateTo} = effectiveDateFromTo(season)

        ctx.emit("loadSlotsForAssignmentWorkflow", {
          userInfo: userinfo.user,
          season,
          viewDateFrom: dateFrom,
          viewDateTo: dateTo,
          onlyShowFieldsHavingSlots: onlyShowFieldsHavingSlots.value,
        })
      }

      const dateRefresh = {
        canRefresh: computed(() => !!props.practiceSlotsAssignmentData.selectedUserSeasonInfo.value.getOrNull()),
        refresh: () : void => {
          const form = props.practiceSlotsAssignmentData
          const p2 = form.selectedUserSeasonInfo.value.getOrNull()
          if (!p2) {
            return
          }

          const season = form.phase1.seasonUID.selectedObj
          const team = p2.teamID.selectedObj
          const userlike = form.phase1.selectedUser.value.userlike

          if (!season || !team || !userlike) {
            return
          }

          const {dateFrom, dateTo} = effectiveDateFromTo(season)

          ctx.emit("loadSlotsForAssignmentWorkflow", {
            userInfo: {
              userlike,
              teamInfo: team,
            },
            season,
            viewDateFrom: dateFrom,
            viewDateTo: dateTo,
            onlyShowFieldsHavingSlots: onlyShowFieldsHavingSlots.value,
          })
        }
      }

      return () => {
        const form = props.practiceSlotsAssignmentData
        return <div data-test="assignSlots-tabBody">
          <div class="my-2">
            <FormKit
              type="select"
              placeholder="Select a season"
              disabled={form.phase1.seasonOptions.disabled}
              options={form.phase1.seasonOptions.options}
              v-model={form.phase1.seasonUID.selectedKey}
              data-test="seasonUID"
              onInput={async (value: any) => {
                if (form.phase1.seasonUID.selectedKey === value) {
                  return
                }

                form.phase1.seasonUID.selectedKey = value
                updateDatesFromSeason(requireNonNull(form.phase1.seasonUID.selectedObj)) // non-null because selection has been made

                form.phase1.userOptions.reset()
                form.phase1.selectedUser.reset()
                form.selectedUserSeasonInfo.reset()
                form.workingCalendarInfoForSomeTargetUserSeason.reset()

                tryInitPhase2AndUpdateView()
              }}
            />
            <div class="my-2">
              <div style="display:grid; grid-template-columns: auto auto; grid-gap: 0 .5em;">
                <div class="text-sm font-medium">From</div>
                <div class="text-sm font-medium">To</div>
                <FormKit type="date" disabled={!form.phase1.seasonUID.selectedObj} v-model={dateFrom.value} data-test="dateFrom"/>
                <FormKit type="date" disabled={!form.phase1.seasonUID.selectedObj} v-model={dateTo.value} data-test="dateTo"/>
              </div>
              {dateRefresh.canRefresh.value
                ? <a data-test="dateRefresh-button" class="il-link" onClick={() => dateRefresh.refresh()}>Refresh view</a>
                : null}
              {form.phase1.seasonUID.selectedObj && (!form.phase1.seasonUID.selectedObj.seasonWeeks || !form.phase1.seasonUID.selectedObj.coreProgramSeasonalInfo?.seasonStart)
                ? <div class="my-1 text-sm text-red-600">Note: Season has no declared start date or season length, you may need to configure the dates manually.</div>
                : null}
            </div>
          </div>

          {form.mode === "admin"
            ? <PracticeSchedulerUserLookupElement
              class="my-2"
              season={form.phase1.seasonUID.selectedObj}
              searchResults={form.phase1.userOptions.underlying}
              selectedUser={(() => {
                assertIs(form.phase1.selectedUser.value.type, "admin")
                return form.phase1.selectedUser.value.userlike
              })()}
              onLookupRequest={searchText => {
                form.selectedUserSeasonInfo.reset()
                form.workingCalendarInfoForSomeTargetUserSeason.reset()
                form.phase1.selectedUser.value = {type: "admin", userlike: null}
                form.phase1.userOptions.run(() => findSlotAssignmentUserCandidates(axiosAuthBackgroundInstance, {
                  seasonUID: form.phase1.seasonUID.selectedKey,
                  searchText: searchText
                }))
              }}
              onSelectedUser={async userish => {
                form.phase1.selectedUser.value = {type: "admin", userlike: userish}
                tryInitPhase2AndUpdateView();
              }}
            />
            : null}

          {form.selectedUserSeasonInfo.value.status === "resolved"
            ? (() => {
              const p2 = form.selectedUserSeasonInfo.value.data
              return <div class="my-2">
                {(() => {
                  const seasonName = requireNonNull(form.phase1.seasonUID.selectedObj).seasonName
                  if (form.phase1.selectedUser.value.type === "self") {
                    return <div>Select a team you coach for {seasonName}</div>
                  }

                  const {firstName, lastName} = requireNonNull(form.phase1.selectedUser.value.userlike)
                  return <div>Select a team {firstName} {lastName} coaches for {seasonName}</div>
                })()}

                <FormKit
                  type="select"
                  placeholder="Select a team"
                  disabled={p2.teamOptions.disabled}
                  options={p2.teamOptions.options}
                  v-model={p2.teamID.selectedKey}
                  data-test="teamID"
                  onInput={(val: any) => {
                    if (p2.teamID.selectedKey === val) {
                      return;
                    }

                    p2.teamID.selectedKey = val

                    const season = requireNonNull(form.phase1.seasonUID.selectedObj)

                    const {dateFrom, dateTo} = effectiveDateFromTo(season)

                    ctx.emit("loadSlotsForAssignmentWorkflow", {
                      userInfo: {
                        userlike: requireNonNull(form.phase1.selectedUser.value.userlike),
                        teamInfo: requireNonNull(p2.teamID.selectedObj),
                      },
                      season,
                      viewDateFrom: dateFrom,
                      viewDateTo: dateTo,
                      onlyShowFieldsHavingSlots: onlyShowFieldsHavingSlots.value,
                    })
                  }}
                />
                {p2.teamID.selectedObj
                  ? form.workingCalendarInfoForSomeTargetUserSeason.alreadyHasRecurringSelectionForTheseTeams.has(p2.teamID.selectedKey)
                    ? <div class="my-2">{AlreadyHasARecurringAssignmentThisSeasonTeam(form).msg}</div>
                    : null
                  : null}
              </div>
            })()
            : form.selectedUserSeasonInfo.value.status === "pending"
            ? <div class="flex items-center gap-2"><SoccerBall/>Loading...</div>
            : null}
        </div>
      }
    })()

    const tabDefs = computed<TabDef<PracticeSchedulerActionTabID>[]>(() => {
      const tabs : TabDef<PracticeSchedulerActionTabID>[] = []

      const canCrudPracticeSlots = !!props.createPracticeSlotsForm
      const canAssignPracticeSlots = true // if you've gotten here, your at least able to self-assign, and some may be able to assign others to slots (practiceScheduler super users)
      const canGetReport = props.mode === "admin"
      const canManageAssignmentPermits = props.mode === "admin"

      if (canCrudPracticeSlots) {
        tabs.push({
          id: PracticeSchedulerActionTabID.createPracticeSlots,
          "data-test": "createPracticeSlots",
          label: "Create Practice Slots",
          render: createPracticeSlotsTab,
        })
      }

      if (canAssignPracticeSlots) {
        tabs.push({
          id: PracticeSchedulerActionTabID.assignSlots,
          "data-test": "assignPracticeSlots",
          label: "Assign Practice Slots",
          render: assignCoachTab,
        })
      }

      if (canGetReport) {
        tabs.push({
          id: PracticeSchedulerActionTabID.reporting,
          "data-test": "practiceSchedulerReport",
          label: "Reporting",
          // TODO: clarify when store can be null? Shouldn't offer the tab if it is null, yeah?
          render: () => props.reportingStore
            ? <PracticeSlotsAssignmentsReportElemen
              initialSeasonUID={props.viewInfo.seasonUID}
              reportingStore={props.reportingStore}
              data-test="PracticeSlotsAssignmentsReportElemen"
            />
            : null,
        })
      }

      if (canManageAssignmentPermits) {
        tabs.push({
          id: PracticeSchedulerActionTabID.permitsMgr,
          "data-test": "permits",
          label: "Assignment Rainchecks / Permits",
          // TODO: clarify when store can be null? Shouldn't offer the tab if it is null, yeah?
          render: () => props.practiceSchedulerPermitsMgrStore
            ? <PracticeSchedulerPermitsMgrElement
              initialSeasonUID={props.viewInfo.seasonUID}
              store={props.practiceSchedulerPermitsMgrStore}
              data-test="PracticeSchedulerPermitsMgrElement"
              />
            : null
        })
      }

      return tabs
    })

    const tabDefMapping = computed(() => makeTabDefMapping(tabDefs.value))

    return () => {
      return (
        <div class="il-webkit-select-with-text-sm-line-height-kludge" style={`--fk-padding-input:${fkPaddingInput};`}>
          <AutoModal data-test="editDaysPerWeekModal" controller={editDaysPerWeekModalController}/>
          <Tabs
            onChangeSelectedIndex={idx => {
              ctx.emit("changeTab", tabDefMapping.value.idx2Id[idx])
            }}
            selectedIndex={tabDefMapping.value.id2Idx[props.selectedTabId]}
            tabDefs={tabDefs.value}
          />
        </div>
      )
    }
  }
})

export const PracticeSchedulerUserLookupElement = defineComponent({
  props: {
    season: vReqT<PracticeSchedulerSeason | null>(),
    searchResults: vReqT<ReifiedPromise<FindSlotAssignmentUserCandidatesResult[]>>(),
    selectedUser: vReqT<null | FindSlotAssignmentUserCandidatesResult>(),
    kludge_noCoachColumn: vOptT(false), // affecting html output should really be driven by a slot
  },
  emits: {
    selectedUser: (_: null | FindSlotAssignmentUserCandidatesResult) => true,
    lookupRequest: (_searchText: string) => true,
  },
  setup(props, ctx) {
    const searchText = ref("")
    const selectedUserID = computed(() => props.selectedUser?.userID || "")
    const lastSearch = ref<Readonly<{seasonName: string, searchText: string}>>({seasonName: "", searchText: ""})

    const doLookup = async () : Promise<void> => {
      assertNonNull(props.season)
      lastSearch.value = {
        seasonName: props.season.seasonName,
        searchText: searchText.value
      }
      ctx.emit("lookupRequest", searchText.value)
    }

    const handleClickedUser = (v: FindSlotAssignmentUserCandidatesResult) => {
      if (selectedUserID.value === v.userID) {
        return
      }
      ctx.emit("selectedUser", v)
    }

    const perItemVueKeyAndRadioID = (() => {
      const xkey = nextGlobalIntlike()
      return (v: FindSlotAssignmentUserCandidatesResult) => `UserLookupRadio-${xkey}-${v.userID}`
    })()

    const fkSearchText = FK_nodeRef()

    return () => <div data-test="UserLookup" class="relative" style="--fk-margin-outer: none;">
      <FormKit type="form" actions={false} onSubmit={doLookup}>
        <div class="flex items-center gap-2" style="width:var(--fk-max-width-input);">
          <div class="flex-grow">
            <FormKit
              type="text"
              ref={fkSearchText}
              v-model={searchText.value}
              validation={[["required"]]}
              validationLabel="User search"
              disabled={!props.season}
              placeholder={props.season ? "User Search" : "User Search (select a season first)"}
              data-test="userSearch-input"
            />
          </div>
          <Btn2 type="submit" class="px-2 py-1">Search</Btn2>
        </div>
        <div>
          <FormKitMessages node={fkSearchText.value?.node}/>
        </div>
        {props.searchResults.status === "pending"
          ? <div class="mt-2 flex items-center gap-2"><SoccerBall/>Loading...</div>
          : props.searchResults.status === "error"
          ? <div class="mt-2">Sorry, something went wrong</div>
          : props.searchResults.status === "resolved"
          ? <div class="mt-2">
            {props.searchResults.data.length === 0
              ? <div>No results for '{lastSearch.value.searchText}' in season '{lastSearch.value.seasonName}'</div>
              : <table >
                <tr>
                  <td class="border p-1">{/*radio placeholder*/}</td>
                  <td class="border p-1">Name</td>
                  <td class="border p-1">Email</td>
                  <td class="border p-1">Risk Status</td>
                  <td class="border p-1">Risk Status Exp.</td>
                  {props.kludge_noCoachColumn
                    ? null
                    : <td class="border p-1">Coach Assignments</td>}
                </tr>
                {props.searchResults.data.map(v => {
                  const keyAndID = perItemVueKeyAndRadioID(v)
                  return <tr key={keyAndID}>
                      <td class="border py-1 px-2">
                        <input
                          type="radio"
                          class="transition"
                          name="UserLookupRadio"
                          id={keyAndID}
                          checked={selectedUserID.value === v.userID}
                          value={v.userID}
                          onInput={() => handleClickedUser(v)}
                          data-test="userLookup-radio"
                        />
                      </td>
                      <td class="border p-1">
                        <label for={keyAndID}>{v.firstName} {v.lastName}</label>
                      </td>
                      <td class="border p-1">
                        <label for={keyAndID}>{v.email}</label>
                      </td>
                      <td class="border p-1">
                        <label for={keyAndID}>{v.riskStatus}</label>
                      </td>
                      <td class="border p-1">
                        <label for={keyAndID}>{dayjsOr(v.riskStatusExpiration)?.format("MM/DD/YYYY") || ""}</label>
                      </td>
                      {props.kludge_noCoachColumn
                        ? null
                        : <td class="border p-1">
                          <label for={keyAndID}>{v.coachAssignments.map(ca => teamDesignationAndMaybeName(ca)).join(", ")}</label>
                        </td>}
                    </tr>
                })}
              </table>}
          </div>
          : null}
      </FormKit>
    </div>
  }
})

/**
 * K should NOT extend Set<>, because most v-model scenarios don't know how to deal with a Set
 */
export interface MutUiSelection<K extends string | string[], T> {
  selectedKey: K,
  /**
   * is expected to be a computed thing that updates wrt changes to `selectedKey`
   */
  readonly selectedObj: T
}

export function MutUiSelection<K extends string | string[], T>(getter: (selection: K) => T, initialValue: K) : MutUiSelection<K,T> {
  const v = ref(initialValue)
  const vv = computed(() => getter(v.value as K))
  return {
    get selectedKey() { return v.value as K},
    set selectedKey(fresh ) { v.value = fresh as VueGenericRefKludge<UnwrapRef<K>> },
    get selectedObj() { return vv.value },
  }
}

export type CreatePracticeSlotsFormStore = ReturnType<typeof CreatePracticeSlotsFormStore>
export function CreatePracticeSlotsFormStore(args: {seasons: PracticeSchedulerSeason[], fields: Field[], divisions: Division[]}) {
  const selectedSeasonUID = ref("")
  const selectedSeason = computed(() => args.seasons.find(v => v.seasonUID === selectedSeasonUID.value) || null)

  const selectedFieldUID = ref("")
  const selectedField = computed(() => args.fields.find(v => v.fieldUID === selectedFieldUID.value) || null)

  const selectedDivIDs = ref<Guid[]>([])
  const _divOrNull = (divID: Guid) => args.divisions.find(v => v.divID === divID) || null
  const selectedDivisions = computed(() => selectedDivIDs.value.map(_divOrNull).filter(v => v !== null))

  const asOptions = <T,>(vs: T[], f: (v: T) => UiOption) => vs.length === 0
    ? noAvailableOptions()
    : {disabled: false, options: vs.map(f)}

  const startWeekOptions = ref(computeStartWeekOptions(null))

  const computeRepeatWeeksOptions = (season: PracticeSchedulerSeason | null) : UiOptions<Integerlike> => {
    if (!season) {
      return noAvailableOptions("Select a season") as UiOptions<Integerlike>
    }

    const ceil = season.seasonWeeks + 2 // a little extra to accomodate "initial week is not a full week"
    // repeat 0 weeks === "just create this week"
    // repeat 1 weeks === "create this week and next week"
    // ...
    const result : UiOption<Integerlike>[] = [{label: "Initial week only", value: "0"}]
    for (let i = 1; i <= ceil; i++) {
      // "repeat for 2 weeks" means "create on start date and 1 week after start date"
      result.push({
        label: `${i+1} weeks`,
        value: i.toString() as Integerlike,
      })
    }
    return {
      disabled: false,
      options: result
    }
  }

  const repeatWeeksOptions = ref(computeRepeatWeeksOptions(null))

  const initialWeekStart = ref("")
  const startHour24 = ref<Integerlike>(8)
  const startMinute = ref<Integerlike>(0)
  const daysOfWeekPerWeek = ref<Integerlike<JS_DayOfWeek>[]>([
    JS_DayOfWeek.MONDAY,
    JS_DayOfWeek.TUESDAY,
    JS_DayOfWeek.WEDNESDAY,
    JS_DayOfWeek.THURSDAY,
    JS_DayOfWeek.FRIDAY,
  ])
  const slotCountPerDay = ref<Integerlike>(1)
  const slotDurationMinutes = ref<Integerlike>(60)
  const repeatWeeks = ref<Integerlike>(0)
  const comment = ref("")
  const allowableTeamCount = ref<Integerlike>(2) // default from a config somewhere? or just a general reasonable default?
  const generateAsRecurrenceGroup = ref(true)
  const visibleOnOrAfter = ref<DateTimelike>("")

  return {
    seasonUID: {
      get selectedKey() { return selectedSeasonUID.value },
      set selectedKey(v) { selectedSeasonUID.value = v },
      get selectedObj() { return selectedSeason.value },
    },
    fieldUID: {
      get selectedKey() { return selectedFieldUID.value },
      set selectedKey(v) { selectedFieldUID.value = v },
      get selectedObj() { return selectedField.value },
    },
    divIDs: {
      get selectedKey() { return selectedDivIDs.value },
      set selectedKey(v) { selectedDivIDs.value = v },
      get selectedObj() { return selectedDivisions.value },
    },

    get initialWeekStart() {return initialWeekStart.value },
    get startHour24() { return startHour24.value },
    get startMinute() { return startMinute.value },
    get daysOfWeekPerWeek() { return daysOfWeekPerWeek.value },
    get slotCountPerDay() { return slotCountPerDay.value },
    get slotDurationMinutes() { return slotDurationMinutes.value },
    get repeatWeeks() { return repeatWeeks.value },
    get comment() { return comment.value },
    get generateAsRecurrenceGroups() { return generateAsRecurrenceGroup.value },
    get visibleOnOrAfter() { return visibleOnOrAfter.value },
    get allowableTeamCount() { return allowableTeamCount.value },

    set initialWeekStart(v) { initialWeekStart.value = v },
    set startHour24(v) { startHour24.value = v },
    set startMinute(v) { startMinute.value = v },
    set daysOfWeekPerWeek(v) { daysOfWeekPerWeek.value = v },
    set slotCountPerDay(v) { slotCountPerDay.value = v },
    set slotDurationMinutes(v) { slotDurationMinutes.value = v },
    set repeatWeeks(v) { repeatWeeks.value = v },
    set comment(v) { comment.value = v },
    set generateAsRecurrenceGroups(v) { generateAsRecurrenceGroup.value = v },
    set visibleOnOrAfter(v) { visibleOnOrAfter.value = v},
    set allowableTeamCount(v) { allowableTeamCount.value = v },

    seasonOptions: asOptions(args.seasons, v => ({label: v.seasonName, value: v.seasonUID})),
    fieldOptions: asOptions(args.fields, v => ({label: v.fieldAbbrev, value: v.fieldUID})),
    divisionOptions: asOptions(args.divisions, v => ({label: v.displayName || v.division, value: v.divID})),
    get startDateWeekOptions() { return startWeekOptions.value },
    recomputeStartWeekOptions(season: PracticeSchedulerSeason | null) {
      startWeekOptions.value = computeStartWeekOptions(season)

      // we could choose to not do this if the option currently selected exists in the new list, but it's sort of jarring
      // in the UI that some seasons change the selected week, and some don't. so we just always update the selected value
      // to the new first option.
      this.initialWeekStart = forceCheckedIndexedAccess(startWeekOptions.value.options, 0)?.value || ""
    },
    get repeatWeeksOptions() { return repeatWeeksOptions.value },
    recomputeRepeatWeeksOptions(season: PracticeSchedulerSeason | null) {
      repeatWeeksOptions.value = computeRepeatWeeksOptions(season)
      if (repeatWeeksOptions.value.options.find(opt => opt.value /*weakEq*/ != this.repeatWeeks)) {
        this.repeatWeeks = forceCheckedIndexedAccess(repeatWeeksOptions.value.options, 0)?.value || "0"
      }
    },
  }
}

type SelectedUserish =
  | {type: "self", userlike: {userID: Guid, firstName: string, lastName: string}}
  | {type: "admin", userlike: FindSlotAssignmentUserCandidatesResult | null}

export interface SlotSchedulingDataStore {
  mode: "self" | "admin",
  phase1: {
    seasonUID: MutUiSelection<Guid, PracticeSchedulerSeason | null>,
    seasonOptions: UiOptions<Guid>,
    userOptions: ReactiveReifiedPromise<FindSlotAssignmentUserCandidatesResult[]>,
    selectedUser: {
      reset: () => void,
      value: Readonly<SelectedUserish>,
    },
  },
  selectedUserSeasonInfo: {
    value: ReifiedPromise<SlotSchedulingDataStore_SelectedUserSeasonInfo>,
    reset: () => void,
    tryInit: () => Promise<SlotSchedulingDataStore_SelectedUserSeasonInfo | null>,
  },
  /**
   * investigate: shouldn't this be part of phase2? If not, explain why not?
   */
  workingCalendarInfoForSomeTargetUserSeason: {
    slotsAlreadyAssignedForSelectedUser: PracticeSlotAssignment[],
    alreadyHasRecurringSelectionForTheseTeams: Set<Guid>,
    getAvailableOneOffSignupPermits(_: {teamID: Guid}): {practiceSlotAssignmentPermitID: number}[]
    refresh: (args: {userID: Guid, seasonUID: Guid}) => Promise<void>,
    reset: () => void,
  }
}

interface SlotSchedulingDataStore_SelectedUserSeasonInfo {
  teamID: MutUiSelection<Guid, PracticeSchedulerTeamInfo | null>
  teamOptions: UiOptions<Guid>
}

export function SlotSchedulingDataStore(mode: "self" | "admin", d1: PracticeSlotAssignmentData1) : SlotSchedulingDataStore {
  const phase1 : SlotSchedulingDataStore["phase1"] = (() => {
    const selectedSeasonUID = ref("")
    const selectedSeason = computed(() => d1.seasons.find(v => v.seasonUID === selectedSeasonUID.value) || null)
    const userOptions = ReactiveReifiedPromise<FindSlotAssignmentUserCandidatesResult[]>(undefined, {defaultDebounce_ms: 250})
    const freshSelectedUser = () : SelectedUserish => mode === "self"
      ? (() => {
        const u = requireNonNull(User.userData)
        return {type: "self", userlike: {userID: u.userID, firstName: u.firstName, lastName: u.lastName}}
      })()
      : {type: "admin", userlike: null}
    const selectedUser = ref(freshSelectedUser())

    return {
      seasonUID: {
        get selectedKey() { return selectedSeasonUID.value },
        set selectedKey(v) { selectedSeasonUID.value = v },
        get selectedObj() { return selectedSeason.value },
      },
      seasonOptions: d1.seasons.length === 0
        ? noAvailableOptions()
        : {disabled: false, options: d1.seasons.map(v => ({label: v.seasonName, value: v.seasonUID}))},
      userOptions,
      selectedUser: {
        get value() { return selectedUser.value },
        set value(v) { selectedUser.value = v },
        reset() { selectedUser.value = freshSelectedUser() },
      },
    }
  })()

  const selectedUserSeasonInfo = ReactiveReifiedPromise<SlotSchedulingDataStore_SelectedUserSeasonInfo>(undefined, {defaultDebounce_ms: 250}, "shallowRef")
  const tryInitPhase2 = async () => {
    const userID = phase1.selectedUser.value.userlike?.userID
    const seasonUID = phase1.seasonUID.selectedKey

    if (!userID || !seasonUID) {
      return null // shouldn't happen
    }

    return selectedUserSeasonInfo.run(async () => {
      const data = await getPracticeSlotAssignmentUserSeasonInfo(axiosAuthBackgroundInstance, {userID, seasonUID})
      const selectedTeamID = ref(forceCheckedIndexedAccess(data.teams, 0)?.team.teamID || "")
      const selectedTeam = computed(() => data.teams.find(v => v.team.teamID === selectedTeamID.value) || null)
      const teamOptions : UiOptions<Guid> = data.teams.length === 0
        ? noAvailableOptions()
        : {disabled: false, options: data.teams.map(v => ({label: teamDesignationAndMaybeName(v.team), value: v.team.teamID}))}

      return {
        teamID: {
          get selectedKey() { return selectedTeamID.value },
          set selectedKey(v) { selectedTeamID.value = v },
          get selectedObj() { return selectedTeam.value },
        },
        teamOptions,
      }
    }).getResolvedOrFail()
  }

  return {
    mode,
    phase1,
    selectedUserSeasonInfo: {
      get value() { return selectedUserSeasonInfo.underlying },
      reset: () => selectedUserSeasonInfo.reset(),
      tryInit: tryInitPhase2,
    },
    workingCalendarInfoForSomeTargetUserSeason: (() => {
      let focusedSeasonUID = ""
      let focusedUserID = ""
      const slotsAlreadyAssignedForSelectedUser = ref<PracticeSlotAssignment[]>([])
      const alreadyHasRecurringSelectionForTheseTeams = ref(new Set<Guid>())
      const availablePermitsForUserSeason = ref<{seasonUID: Guid, practiceSlotAssignmentPermitID: number, teamID: Guid}[]>([])

      return {
        get slotsAlreadyAssignedForSelectedUser() { return slotsAlreadyAssignedForSelectedUser.value },
        get alreadyHasRecurringSelectionForTheseTeams() { return alreadyHasRecurringSelectionForTheseTeams.value },
        getAvailableOneOffSignupPermits(args: {teamID: Guid}) {
          return availablePermitsForUserSeason.value.filter(v => {
            assertTruthy(v.seasonUID === focusedSeasonUID) // sanity check, all the data should be for the contextually appropriate season
            return v.teamID === args.teamID
          })
        },
        reset() {
          focusedSeasonUID = ""
          focusedUserID = ""
          slotsAlreadyAssignedForSelectedUser.value.length = 0 // delete everything
          alreadyHasRecurringSelectionForTheseTeams.value.clear()
          availablePermitsForUserSeason.value.length = 0
        },
        async refresh(args: {userID: Guid, seasonUID: Guid}) {
          this.reset()

          focusedSeasonUID = args.seasonUID
          focusedUserID = args.userID

          await listPracticeSlotAssignments(axiosAuthBackgroundInstance, {userID: args.userID, seasonUID: args.seasonUID, includeDeleted: false}).then(vs => {
            for (const v of vs) {
              slotsAlreadyAssignedForSelectedUser.value.push(v)
              if (v.practiceSlotAssignmentGroupID !== null) {
                alreadyHasRecurringSelectionForTheseTeams.value.add(v.coachAssignment.team.teamID)
              }
            }
          })

          // yeah, this is phase2 stuff ... we need to merge this and phase2
          await getPracticeSlotAssignmentUserSeasonInfo(axiosAuthBackgroundInstance, {userID: args.userID, seasonUID: args.seasonUID}).then(data => {
            availablePermitsForUserSeason.value = data.availablePermits
          })
        }
      }
    })()
  }
}

const fkPaddingInput = ".325em";

function commonTimeOptions() {
  const timeStartHourOptions = (() => {
    const result : UiOption[] = []
    for (let i = 7; i <= 23; i++) {
      result.push({label: dayjs().hour(i).format("h a"), value: i.toString()})
    }
    return result;
  })()

  const timeStartMinuteOptions = (() => {
    const result : UiOption[] = []
    for (let i = 0; i < 60; i += 5) {
      result.push({label: i.toString().padStart(2, "0"), value: i.toString()})
    }
    return result;
  })()

  const gameLengthMinutesOptions = (() => {
    const result : UiOption[] = []
    for (let i = 5; i <= 120; i += 5) {
      result.push({label: i.toString(), value: i.toString()})
    }
    return result;
  })()

  return {
    timeStartHourOptions,
    timeStartMinuteOptions,
    gameLengthMinutesOptions,
  }
}

export enum PracticeSchedulerActionTabID {
  createPracticeSlots = "createPracticeSlots",
  assignSlots = "assignPracticeSlots",
  reporting = "reporting",
  permitsMgr = "permitsMgr",
}

export function AlreadyHasARecurringAssignmentThisSeasonTeam(store: SlotSchedulingDataStore) : {hasRecurringSelection: boolean, msg: string} {
  const userSeason = store.selectedUserSeasonInfo.value.getOrNull()
  if (!userSeason) {
    return {hasRecurringSelection: false, msg: ""}
  }

  const season = store.phase1.seasonUID.selectedObj
  const teaminfo = userSeason.teamID.selectedObj

  if (!season || !teaminfo) {
    return {hasRecurringSelection: false, msg: ""}
  }

  const alreadyHasRecurringSelection = store.workingCalendarInfoForSomeTargetUserSeason.alreadyHasRecurringSelectionForTheseTeams.has(userSeason.teamID.selectedKey)

  if (!alreadyHasRecurringSelection) {
    return {hasRecurringSelection: false, msg: ""}
  }

  const msg = (() => {
    if (store.phase1.selectedUser.value.type === "self") {
      return "You already have a recurring assignment for"
    }
    else {
      const user = requireNonNull(store.phase1.selectedUser.value.userlike)
      return `${user.firstName} ${user.lastName} already has a recurring assignment for`
    }
  })()

  return {
    hasRecurringSelection: true,
    msg: `${msg} ${season.seasonName}, ${teamDesignationAndMaybeName(teaminfo.team)}`
  }
}

/**
 * given a season, produce its start/end dates
 * tries to handle cases of missing seasonStart, and/or missing seasonWeeks
 */
export function defaultSeasonDateRange(season: PracticeSchedulerSeason | null, defaultStartDate = dayjs().subtract(1, "week")) {
  const seasonStart = dayjsOr(season?.coreProgramSeasonalInfo?.seasonStart) ?? defaultStartDate
  const dateFrom = seasonStart.format(DAYJS_FORMAT_HTML_DATE)
  const dateTo = seasonStart.add(season?.seasonWeeks || 8, "weeks").format(DAYJS_FORMAT_HTML_DATE)
  return {dateFrom, dateTo}
}

/**
 * returns a {dateFrom, dateTo} where they are some mon/fri pair
 */
export function practiceCentricSeasonCurrentWeek(season: PracticeSchedulerSeason | null, today = dayjs()) {
  let start = practiceCentricSeasonCurrentWeek_nearestMonday(today)
  const seasonRange = defaultSeasonDateRange(season)
  if (dayjs(seasonRange.dateFrom).isSameOrBefore(start) && dayjs(seasonRange.dateTo).isAfter(start)) {
    // no-op
  }
  else {
    start = practiceCentricSeasonCurrentWeek_nearestMonday(dayjs(seasonRange.dateFrom))
  }

  assertTruthy(start.day() === JS_DayOfWeek.MONDAY);

  return {
    dateFrom: start.format(DAYJS_FORMAT_HTML_DATE), // some mon
    dateTo: start.add(4, "days").format(DAYJS_FORMAT_HTML_DATE), // some fri
  }
}

function practiceCentricSeasonCurrentWeek_nearestMonday(today: Dayjs) : Dayjs {
  let start = today;
  if (start.day() === JS_DayOfWeek.SATURDAY || start.day() === JS_DayOfWeek.SUNDAY) {
    while (start.day() !== JS_DayOfWeek.MONDAY) {
      start = start.add(1, "day")
    }
    return start;
  }
  else {
    while (start.day() !== JS_DayOfWeek.MONDAY) {
      start = start.subtract(1, "day")
    }
    return start;
  }
}

/**
 * generally we are focused on a user/team pair
 */
export interface SlotAssignmentUserBinding {
  userlike: Userlike,
  teamInfo: PracticeSchedulerTeamInfo,
}

export interface Userlike {
  userID: Guid,
  firstName: string,
  lastName: string,
}

function daysPerWeekUiString(days: Integerlike<JS_DayOfWeek>[], weekStartDay: Datelike) : string {
  const dayBase = dayjs(weekStartDay).day();
  return days
    .map(parseIntOrFail)
    .sort(sortBy(v => {
      return v < dayBase ? (v + 7) : v // keep "week start day" as first, and then everything sorts in order after that
    }))
    .map(v => dayjs().day(v).format("ddd"))
    .join(", ")
}

export const computeStartWeekOptions = (season: PracticeSchedulerSeason | null) : UiOptions => {
  if (!season) {
    return noAvailableOptions("Select a season")
  }

  const baseDate = (() => {
    let v = dayjsOr(season.coreProgramSeasonalInfo?.seasonStart) ?? dayjs()
    while (v.day() !== JS_DayOfWeek.MONDAY) {
      v = v.subtract(1, "day")
    }
    return v
  })()

  const seasonWeeks = season.seasonWeeks || 8

  return {
    disabled: false,
    options: rangeExc(0, seasonWeeks).map((weekIdx) : UiOption => {
      const d = baseDate.add(weekIdx, "weeks")
      return {
        label: d.format("dddd, MMM/DD/YYYY"),
        value: d.format(DAYJS_FORMAT_HTML_DATE),
      }
    })
  }
}
