import { defineComponent, ref, onMounted } from 'vue'
import Portlet from 'src/components/Portlets/Portlet.vue'

import { UpcomingGamesPortlet } from 'src/interfaces/InleagueApiV1'
import { sortByDayJS, vReqT } from 'src/helpers/utils'
import dayjs from 'dayjs'
import { teamDesignationAndMaybeName } from '../GameScheduler/calendar/GameScheduler.shared'
import { directionsToPlayingFieldURL } from 'src/helpers/GoogleMaps'

export const UpcomingPracticesPortletElement = defineComponent({
  name: 'UpcomingPractices',
  props: {
    portlet: vReqT<UpcomingGamesPortlet>(),
  },
  setup(props) {
    const ready = ref(false)

    onMounted(async () => {
      // warn: sorting stuff we don't own
      props.portlet.upcomingPractices.sort(sortByDayJS(v => v.practiceSlot.start))
      ready.value = true;
    })

    return () => {
      if (!ready.value || props.portlet.upcomingPractices.length === 0) {
        return null
      }

      return <Portlet label="Upcoming Practices" icon={props.portlet.fontAwesome || "calendar"} prefix="fas" data-test="UpcomingPractices">
        <div class="p-2">
          {props.portlet.upcomingPractices.map((e,i) => {
            const isFirst = i === 0
            return <div class={["p-1 rounded-md border", isFirst ? "" : "mt-2"]} data-test={`practiceSlotAssignmentID=${e.practiceSlotAssignmentID}`}>
              <div>
                <a class="il-link" href={directionsToPlayingFieldURL(e.practiceSlot.field)} target="_blank">{e.practiceSlot.field.fieldAbbrev}</a>
              </div>
              <div>{dayjs(e.practiceSlot.start).format("MMM/D/YYYY")}, {dayjs(e.practiceSlot.start).format("h:mm a")} - {dayjs(e.practiceSlot.end).format("h:mm a")}</div>
              <div>{teamDesignationAndMaybeName(e.coachAssignment.team)}</div>
            </div>
          })}
        </div>
      </Portlet>
    }
  }
})
