import { axiosAuthBackgroundInstance } from "src/boot/AxiosInstances";
import { Field } from "src/composables/InleagueApiV1";
import { ReactiveReifiedPromise } from "src/helpers/ReifiedPromise";
import { accentAwareCaseInsensitiveCompare, exhaustiveCaseGuard, noAvailableOptions, parseIntOr, requireNonNull, sortBy, sortByDayJS, sortByMany, UiOptions, vReqT, WithDefiniteExpanded } from "src/helpers/utils";
import { Season, Guid } from "src/interfaces/InleagueApiV1";
import { PracticeSlot, PracticeSlotAssignment, listPracticeSlots } from "./PracticeScheduler.io";
import { MutUiSelection } from "./PracticeSchedulerActions";
import { computed, defineComponent, onMounted } from "vue";
import { FormKit } from "@formkit/vue";
import dayjs from "dayjs";
import { SoccerBall } from "src/components/SVGs";
import { BasicAutoTable2, typedBasicAutoTable2Props } from "src/modules/BasicAutoTable";
import { freshSortState, inferColIDs } from "src/modules/TableUtils";
import { teamDesignationAndMaybeName } from "./GameScheduler.shared";

export const PracticeSlotsAssignmentsReportElemen = defineComponent({
  props: {
    reportingStore: vReqT<PracticeSlotsAssignmentsReportStore>(),
    initialSeasonUID: vReqT<Guid>(),
  },
  setup(props) {
    const tryLoadReport = () => {
      const season = props.reportingStore.seasonUID.selectedObj
      if (!season) {
        return
      }

      props.reportingStore.loadReport({season})
    };

    const colDefs = inferColIDs<XRow>()([
      {
        id: "field",
        headerClass: "border p-1",
        cellClass: "border p-1",
        label: "Field",
        html: v => v.slot.field.fieldAbbrev,
        sort: sortBy(v => v.slot.field.fieldAbbrev),
      },
      {
        id: "day",
        headerClass: "border p-1",
        cellClass: "border p-1 whitespace-nowrap",
        label: "Day",
        html: v => dayjs(v.slot.start).format("M/DD/YY"),
        sort: sortByDayJS(v => v.slot.start),
      },
      {
        id: "start",
        headerClass: "border p-1",
        cellClass: "border p-1 whitespace-nowrap",
        label: "Start",
        html: v => dayjs(v.slot.start).format("h:mm a"),
        sort: sortByDayJS(v => v.slot.start),
      },
      {
        id: "end",
        headerClass: "border p-1",
        cellClass: "border p-1 whitespace-nowrap",
        label: "End",
        html: v => dayjs(v.slot.end).format("h:mm a"),
        sort: sortByDayJS(v => v.slot.end),
      },
      {
        id: "user-first",
        headerClass: "border p-1",
        cellClass: "border p-1",
        label: "First Name",
        html: v => v.assignment ? v.assignment.coachAssignment.user.firstName : "",
        sort: (l,r) => accentAwareCaseInsensitiveCompare(l.assignment?.coachAssignment.user.firstName || "", r.assignment?.coachAssignment.user.firstName || "")
      },
      {
        id: "user-last",
        headerClass: "border p-1",
        cellClass: "border p-1",
        label: "Last Name",
        html: v => v.assignment ? v.assignment.coachAssignment.user.lastName : "",
        sort: (l,r) => accentAwareCaseInsensitiveCompare(l.assignment?.coachAssignment.user.lastName || "", r.assignment?.coachAssignment.user.lastName || "")
      },
      {
        id: "email",
        headerClass: "border p-1",
        cellClass: "border p-1",
        label: "Email",
        html: v => v.assignment?.coachAssignment.user.email || "",
      },
      {
        id: "team",
        headerClass: "border p-1",
        cellClass: "border p-1",
        label: "Team",
        html: v => v.assignment ? teamDesignationAndMaybeName(v.assignment.coachAssignment.team) : "",
      },
      {
        id: "division",
        headerClass: "border p-1",
        cellClass: "border p-1",
        label: "Division",
        html: v => {
          if (!v.assignment) {
            return ""
          }
          else {
            const div = v.assignment.coachAssignment.division
            return div.displayName || div.division
          }
        },
        sort: sortByMany(
          sortBy(v => parseIntOr(v.assignment?.coachAssignment.division.divNum, Infinity)),
          sortBy(v => v.assignment?.coachAssignment.division.gender || "")
        )
      },
    ]);

    const sortState = freshSortState(colDefs)
    sortState.reconfigure([{colID: "field", dir: "asc"}, {colID: "start", dir: "asc"}])

    const xlsxConfig = computed(() => {
      if (props.reportingStore?.reportResolver.status === "resolved") {
        const data = props.reportingStore.reportResolver.data
        return {
          filename: `Practice_Assignments_${data.season.seasonName.replace(/ +/g, "_")}.xlsx`,
        }
      }
      else {
        return undefined
      }
    })

    const rowKeyAndTestAttr = (row: XRow) => `practiceSlotID=${row.slot.practiceSlotID}/practiceSlotAssignmentID=${row.assignment?.practiceSlotAssignmentID ?? "none"}`

    onMounted(() => {
      if (props.reportingStore.seasonOptions.options.find(v => v.value === props.initialSeasonUID)) {
        props.reportingStore.seasonUID.selectedKey = props.initialSeasonUID
        props.reportingStore.loadReport({season: requireNonNull(props.reportingStore.seasonUID.selectedObj)})
      }
    })

    return () => {
      const store = props.reportingStore
      return <div>
        <FormKit type="form" actions={false} onSubmit={() => tryLoadReport()}>
          <div class="font-medium text-sm">Season</div>
          <div class="my-2 flex items-center gap-2">
            <FormKit
              type="select"
              disabled={store.seasonOptions.disabled}
              options={store.seasonOptions.options}
              v-model={store.seasonUID.selectedKey}
              placeholder="Choose a Season"
              validation={[["required"]]}
              validationLabel="Season"
              data-test="seasonUID"
              onInput={(value: any) => {
                if (store.seasonUID.selectedKey === value) {
                  return
                }
                store.seasonUID.selectedKey = value
                tryLoadReport()
              }}
            />
          </div>

        </FormKit>
        {store.reportResolver.status === "idle"
          ? null
          : <div class="my-2 overflow-x-auto">
            <BasicAutoTable2
              {...typedBasicAutoTable2Props({
              asXLSX: xlsxConfig.value,
              colDefs: colDefs,
              sortState: sortState,
              rows: store.reportResolver.getOrNull()?.rows ?? [],
              paginationOptions: [25, 50, "ALL"],
              rowKey: row => rowKeyAndTestAttr(row),
              rowAttrs: row => ({"data-test": rowKeyAndTestAttr(row)}),
              noData: () => store.reportResolver.status === "idle"
                ? null
                : store.reportResolver.status === "pending"
                ? <div class="flex items-center gap-2"><SoccerBall/>Loading...</div>
                : store.reportResolver.status === "error"
                ? <div>Sorry, something went wrong.</div>
                : store.reportResolver.status === "resolved"
                ? <div>No practice slot assignments for {store.reportResolver.data.season.seasonName}</div>
                : exhaustiveCaseGuard(store.reportResolver)
            })}/>
          </div>}

      </div>
    }
  }
})

type XRow = {slot: WithDefiniteExpanded<PracticeSlot, "field">, assignment: PracticeSlotAssignment | null}

export function PracticeSlotsAssignmentsReportStore(args: {seasons: Season[], fields: Field[]}) {
  const seasonOptions : UiOptions = args.seasons.length === 0
    ? noAvailableOptions()
    : {disabled: false, options: args.seasons.map(v => ({label: v.seasonName, value: v.seasonUID}))};

  const seasonUID = MutUiSelection<Guid, Season | null>(
    seasonUID => args.seasons.find(season => season.seasonUID === seasonUID) ?? null,
    ""
  )

  const reportResolver = ReactiveReifiedPromise<{season: Season, rows: XRow[]}>(undefined, {defaultDebounce_ms: 250})
  const loadReport = (args: {season: Season}) => {
    reportResolver.run(async () => {
      return {
        season: args.season,
        rows: await listPracticeSlots(axiosAuthBackgroundInstance, {seasonUID: args.season.seasonUID, expand: ["activeAssignments", "field"]})
          .then(vs => {
              return (vs as SlotEx[]).flatMap(z => {
                return leftJoin(z, z.activeAssignments)
                  .map(([l,r]) => ({slot: l, assignment: r}))
              })

              function leftJoin<L,R>(l: L, rs: R[]) : [L, R | null][] {
                if (rs.length === 0) {
                  return [[l, null]]
                }
                else {
                  return rs.map(r => [l,r])
                }
              }

              type SlotEx = WithDefiniteExpanded<PracticeSlot, "field" | "activeAssignments">
          })
      }
    })
  }

  return {
    seasonUID,
    seasonOptions,
    loadReport,
    get reportResolver() { return reportResolver.underlying }
  }
}

export type PracticeSlotsAssignmentsReportStore = ReturnType<typeof PracticeSlotsAssignmentsReportStore>
